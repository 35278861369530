
.inline-titles {
    font-size: 20px;
}


/* Capacity */
@media screen and (min-width: 1151px) {
.trisect {
        width: 25vw !important;
}
}
@media screen and (min-width: 851px) {
.trisect {
        width: 35vw !important;
}
.trisection {
        overflow-x: scroll;
}
}
@media screen and (min-width: 851px) {
.trisection {
        float: left;
        max-width: 33%;
}
}
@media screen and (max-width: 850px) {
.trisection {
        float: none;
        max-width: 100%;
}
}