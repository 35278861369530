
.title{
    margin: 0;
    font-family: 'Open Sans Condensed', sans-serif;
    font-weight: 100;
    font-size: 2.5em;
}
.title--light {
    color: #f2f2f2;
}

/* Most browsers will do this, but just in case! */
strong, b {
    font-weight: bold;
}
