
.select-container[data-v-7a733cd1]{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
}
.page-num-container[data-v-7a733cd1]{
    display: flex;
    flex-direction: row;
}
.showing-container[data-v-7a733cd1]{
    display: flex;
    flex-direction: row;
    margin-top: 1rem;
    font-size:12px;
}
.page-num[data-v-7a733cd1]{
    font-family: "Nunito Regular", Helvetica, sans-serif;
    font-size: 10px;
    color: rgba(33, 33, 33, 0.6);
    padding: 6px 12px;
    transition: background-color .3s;
    border: 1px solid #e2f1f8;
    border-radius: 4px;
}
.page-num[data-v-7a733cd1]:hover{
    background-color: rgba(226, 241, 248, 0.8);
    cursor: pointer;
}
.selected-page[data-v-7a733cd1]{
    background-color: #e2f1f8;
    color: #212121;
    padding: 6px 12px;
    border: 1px solid #b0bec5;
    font-size: 10px;
    transition: background-color .3s;
    border-radius: 4px;
}
.selected-page[data-v-7a733cd1]:hover{
    cursor: pointer;
}
.num-container[data-v-7a733cd1]{
    padding: 0px 2px;
}
