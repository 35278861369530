
.sort-icon-container[data-v-758d53e4] {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.sort-icon[data-v-758d53e4] {
    /* viewBox has width:height ratio 48:33 */
    height: 0.4rem; /* ~so roughly 1rem lineheight for full icon */
    width: calc(0.4rem * 48/33);
    cursor: pointer;
}
.sort-icon.off[data-v-758d53e4] {
    fill: #808e95;
}
