
@media screen and (max-width: 850px) {
    /* keep navbar visible and left-positioned */
.c-primary-nav__item {
        display: grid !important;
        max-width: 100% !important;
}
.c-header__nav-container, .c-header__nav-container--slim, .c-header-vertical__nav-container {
        display: flex;
}
.c-primary-nav-vertical__link, .c-primary-nav__link {
        padding: 0.3rem 1rem !important;
}

    /* position dropdown arrow on resize */
ul.c-primary-nav__list-right.js-nav-panel {
        position: absolute;
        right: 100px;
        top: 20px;
}

    /* move page content lower to accomodate vertical navbar */
.flex-page {
        margin-top: 10rem !important;
}
div.c-card.center.outer-box {
        margin-top: 10rem !important;
}
.serviceDeskTabs {
        margin-top: 9rem !important;
}
.c-logo, .c-logo-vertical {
        width: 20%;
}
}
@media screen and (max-width: 749px) {

    /* move page content lower to accomodate vertical navbar */
.flex-page {
        margin-top: 12rem !important;
}
.serviceDeskTabs {
        margin-top: 9rem !important;
}
div.c-card.center.outer-box {
        margin-top: 10rem !important;
}
.c-logo, .c-logo-vertical {
        width: 20%;
}
}

