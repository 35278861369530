
.error-fixed[data-v-10d37cd9]{
    position: fixed;
    top: 15%;
    left: 10%;
    z-index:15;
}
.fade-enter-active[data-v-10d37cd9], .fade-leave-active[data-v-10d37cd9] {
    transition: opacity .5s;
}
.fade-enter[data-v-10d37cd9], .fade-leave-to[data-v-10d37cd9] /* .fade-leave-active below version 2.1.8 */ {
opacity: 0;
}
