
html {
  font-family: Helvetica, sans-serif
}
.page{
  padding: 4em 2vw 0 2vw;
  display: flex;
  flex-direction: column
}
.page__subsection--horizontal-scroll{
  margin: auto;
  width: 92vw;
  overflow: auto;
}
.router-view-pad{
  padding: 1rem 2rem 5rem 2rem;
}

