
.side-menu {
    height: 100vh;
    left: 0;
    position: fixed;
    z-index: 4;
    top: 0;
    width: 2vw;
}
.side-menu__content {
    align-content: center;
    background-color: rgba(10,18,42,0.98);
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: flex-start;
    left: -300px;
    position: fixed;
    top: 0;
    transition: 0s; 
    width: 300px;
}
.side-menu:hover .side-menu__content {
    left: 0;
    transition-delay: 0.3s;
    transition: 0.3s;
}
.side-menu__content__item{
    padding-left: 1em;
    padding-top: 1em;
}