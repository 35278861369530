/*------------------------------------*\
   #TABLE OF CONTENTS
\*------------------------------------*/
/**
* ABSTRACTS..............................Declarations of Sass variables & mixins
* BASE...................................Default element styles
* .....Body
* .....Links
* .....Headings
* .....Forms
* .....Defaults
* LAYOUT.................................Layout-specific styles
* COMPONENTS.............................Component styles
* UTILITIES..............................Utility classes
*/
/*------------------------------------*\
   #ABSTRACTS
\*------------------------------------*/
/*------------------------------------*\
    #VARIABLES
\*------------------------------------*/
/**
 * CONTENTS
 *
 * COLORS
 * Brand Colors...............Globally-available variables and config
 * Neutral Colors.............Grayscale colors, including white and black
 *
 * TYPOGRAPHY
 * Font Families..............The fonts used in the design system
 * Sizing.....................Font sizing
 *
 * LAYOUT
 * Max-widths.................Maximum layout container width
 *

 * SPACING
 * Spacing defaults...........Spacing between elements
 *
 * BORDERS
 * Border Width...............Border thicknesses
 * Border Radius..............Border radius definitions
 *
 * ANIMATION
 * Animation Speed............Transition/animation speed variables
 * Animation easing...........Easing variables
 *
 * BREAKPOINTS
 * Breakpoints................Global breakpoint definitions
 */
/*------------------------------------*\
    #COLORS
\*------------------------------------*/
/**
 * Primary Palette
 *
 */
/**
 * Secondary Palette
 **/
/**
 * Text Palette
 **/
/**
 * Accent Colors
 * 1) plain names for colors to be used intuitively
 **/
/**
 * Tables
 * 1)Accent colors of varied opacities named according to their use
 **/
/**
 * BANs
 * 1)Accent colors of varied opacities named according to their use
 **/
/*------------------------------------*\
    #TYPOGRAPHY
\*------------------------------------*/
/**
 * Font Import
 */
@font-face {
  font-family: "Bitter Regular";
  src: url("/assets/style/ckm-style/fonts/Bitter-Regular.otf?") format('opentype');
}
@font-face {
  font-family: "Nunito Regular";
  font-style: normal;
  font-weight: 200;
  letter-spacing: .25%;
  src: url("/assets/style/ckm-style/fonts/Nunito-Regular.ttf?") format('truetype');
}
@font-face {
  font-family: "Nunito SemiBold";
  font-style: normal;
  font-weight: 200;
  letter-spacing: .25%;
  src: url("/assets/style/ckm-style/fonts/Nunito-SemiBold.ttf?") format('truetype');
}
@font-face {
  font-family: "Nunito Bold";
  font-style: normal;
  font-weight: 200;
  letter-spacing: .25%;
  src: url("/assets/style/ckm-style/fonts/Nunito-Bold.ttf?") format('truetype');
}
/**
 * Font Family
 */
/**
 * Font Sizing
 */
/*------------------------------------*\
    #LAYOUT
\*------------------------------------*/
/**
 * Max Width
 */
/*------------------------------------*\
    #SPACING
\*------------------------------------*/
/**
 * Spacing and offsets
 * 1) Used to space grids and body padding
 * 2) The width of the bullets, used to align list content.
 */
/* 1 */
/* 2 */
/*------------------------------------*\
    #BORDERS
\*------------------------------------*/
/**
 * Border variables
 */
/*------------------------------------*\
    #ANIMATION
\*------------------------------------*/
/**
 * Transition Speed
 */
/*------------------------------------*\
    #BREAKPOINTS
\*------------------------------------*/
/**
 * Breakpoints used in media queries
 */
/*------------------------------------*\
    #MIXINS
\*------------------------------------*/
/*------------------------------------*\
   #BASE
\*------------------------------------*/
/*------------------------------------*\
    #BODY
\*------------------------------------*/
/**
 * HTML base styles
 */
html {
  height: 100%;
}

/**
 * Body base styles
 */
body {
  background: #fff;
  font: 100%/1.5 "Nunito Regular", Helvetica, sans-serif;
  -webkit-text-size-adjust: 100%;
  color: #212121;
  display: flex;
  flex-direction: column;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
}

/*------------------------------------*\
    #BUTTONS
\*------------------------------------*/
/**
 * Button and submit inputs reset
 * 1) These should be styled using c-btn
 */
button, input[type=submit] {
  cursor: pointer;
}
button:focus, input[type=submit]:focus {
    outline: 1px solid #eceff1;
}

/*------------------------------------*\
    #FORMS
\*------------------------------------*/
/**
 * 1) Form element base styles
 */
/**
 * Input placeholder text base styles
 */
::-webkit-input-placeholder {
  color: #212121;
  opacity: 0.5;
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: #212121;
  opacity: 0.5;
}
:-ms-input-placeholder {
  color: #212121;
  opacity: 0.5;
}

/**
 * Fieldset base styles
 */
fieldset {
  border: 0;
  padding: 0;
  margin: 0;
}

/**
 * Legend base styles
 */
legend {
  text-transform: lowercase;
  margin-bottom: 0.25em;
}

/**
 * Label base styles
 */
label {
  display: block;
  padding-bottom: 0.25rem;
}

/**
 * Add font size 100% of form element and margin 0 to these elements
 */
button, input, select, textarea {
  font-family: inherit;
  font-size: 100%;
  margin: 0;
}

/**
 * Text area base styles
 */
textarea {
  resize: none;
}

/**
 * Input  and text area base styles
 */
input, textarea {
  width: 100%;
  border: 1px solid #212121;
  padding: 0.5rem 0.65rem;
}

/**
 * Remove webkit appearance styles from these elements
 */
input[type=text], input[type=search], input[type=url], input[type=number], textarea {
  -webkit-appearance: none;
}

/**
 * Checkbox and radio button base styles
 */
input[type="checkbox"],
input[type="radio"] {
  width: auto;
  margin-right: 0.3em;
}

/**
 * Search input base styles
 */
input[type="search"] {
  -webkit-appearance: none;
  border-radius: 0;
}

/**
 * Remove webkit appearance styles from search input cancel and decoration
 */
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * Select
 * 1) Remove default styling
 */
select {
  display: block;
  font-size: 0.85rem;
  height: 2.5rem;
  width: 100%;
  border: 1px solid #eceff1;
  padding: 0.3rem;
  border-radius: 3px;
  background: #fff;
  color: #212121;
  -moz-appearance: none;
  -webkit-appearance: none;
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMTIgMTEiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDEyIDExOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIgZmlsbD0iIzgwODA4MCI+PHBhdGggZD0iTTIsNGw0LDRsNC00SDJ6Ii8+PC9zdmc+);
  background-position: calc(100% - 0.3rem) 50%;
  background-size: 16px 16px;
  background-repeat: no-repeat;
  /**
   * 1) Remove IE select arrow
   */
  /**
   * 1) Style IE value selector
   */
}
select:focus {
    border-color: #212121;
}
select::-ms-expand {
    /* 1 */
    display: none;
}
select::-ms-value {
    /* 1 */
    background: transparent;
    color: #212121;
}

/*------------------------------------*\
    #HEADINGS
\*------------------------------------*/
/**
 * Heading 1 base styles
 */
h1 {
  font-size: 1.7rem;
  font-family: "Nunito Regular", Helvetica, sans-serif;
  line-height: 1;
  word-wrap: break-word;
  font-weight: normal;
  letter-spacing: -.5%;
}
@media all and (min-width: 46.8em) {
h1 {
      font-size: 2.75rem;
}
}

/**
 * Heading 2 base styles
 */
h2 {
  font-family: "Nunito Regular", Helvetica, sans-serif;
  font-size: 1.5em;
  line-height: 1.2;
  margin-bottom: 0.5rem;
  word-wrap: break-word;
  font-weight: 200;
  letter-spacing: 0;
}

/**
 * Heading 3 base styles
 */
h3 {
  font-weight: normal;
  font-family: "Nunito SemiBold", Helvetica, sans-serif;
  font-size: 1.2em;
  line-height: 1.2;
  margin-bottom: 0.3rem;
  word-wrap: break-word;
  letter-spacing: .25%;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
}

/**
 * Heading 4 base styles
 */
h4 {
  font-weight: normal;
  font-family: "Nunito SemiBold", Helvetica, sans-serif;
  font-size: 1em;
  line-height: 1.2;
  margin-bottom: 0.3rem;
  word-wrap: break-word;
  letter-spacing: 0;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
}

/**
 * Heading 5 base styles
 */
h4 {
  font-weight: normal;
  font-family: "Nunito Regular", Helvetica, sans-serif;
  font-size: 1em;
  line-height: 1.2;
  margin-bottom: 0.3rem;
  word-wrap: break-word;
  letter-spacing: .15%;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
}

/*------------------------------------*\
    #LINKS
\*------------------------------------*/
/**
 * Link base styles
 */
a {
  color: #518668;
  text-decoration: none;
  outline: 0;
  transition: color 0.15s ease-out, box-shadow 0.15s ease-out;
}
a:hover, a:focus {
    color: rgba(80, 134, 104, 0.5);
}

/*------------------------------------*\
    #LISTS
\*------------------------------------*/
/**
 * 1) List base styles
 */
/**
 * Remove list styles from unordered and ordered lists
 */
ol, ul {
  list-style: none;
}

/*------------------------------------*\
    #MAIN ELEMENT
\*------------------------------------*/
/**
 * Main element
 */
[role=main] {
  display: block;
  flex: 1 0 auto;
  padding: 1rem;
}
@media all and (min-width: 62em) {
[role=main] {
      padding-top: 1.5rem;
}
}

/*------------------------------------*\
    #MEDIA
\*------------------------------------*/
/**
 * Responsive image styling
 * 1) Allows for images to flex with varying screen size
 */
img {
  max-width: 100%;
  height: auto;
}

/*------------------------------------*\
    #RESET
\*------------------------------------*/
/**
 * Border-Box http:/paulirish.com/2012/box-sizing-border-box-ftw/
 */
* {
  box-sizing: border-box;
}

/**
 * 1) Zero out margins and padding for elements
 */
html, body, div, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, ol, ul, li, form, legend, label, table, header, footer, nav, section, figure {
  margin: 0;
  padding: 0;
}

/**
 * 1) Set HTML5 elements to display: block
 */
header, footer, nav, section, article, hgroup, figure {
  display: block;
}

/*------------------------------------*\
    #TABLES
\*------------------------------------*/
/**
 * Table
 */
table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}

/**
 * Table header cell
 */
th {
  text-align: left;
}

/**
 * Table row
 */
tr {
  vertical-align: top;
}

/*------------------------------------*\
    #TEXT
\*------------------------------------*/
/**
 * Paragraph base styles
 */
p {
  margin-bottom: 1rem;
}

/**
 * Blockquote base styles
 */
blockquote {
  font-style: italic;
  border-left: 1px solid #212121;
  color: #212121;
  padding-left: 1rem;
  margin-bottom: 1rem;
}

/**
 * Horizontal rule base styles
 */
hr {
  border: 0;
  height: 1px;
  background: #eceff1;
  margin: 1rem 0;
}

/**
 * Selection styles
 */
::-moz-selection {
  background: #B4D5FE;
  /* Gecko Browsers */
}
::selection {
  background: #B4D5FE;
  /* WebKit/Blink Browsers */
}

/**
 * Code base styles
 */
code {
  display: inline-block;
  background: #eceff1;
  border: 1px solid #eceff1;
  padding: .2rem .5rem;
  line-height: 1.2;
  font-size: .85rem;
}

/**
 * Preformatted text base styles
 */
pre {
  background: #eceff1;
  border: 1px solid #eceff1;
  font-size: 1rem;
  padding: 1rem;
  overflow-x: auto;
  /**
   * Remove border from code within preformatted text block
   */
}
pre code {
    border: 0;
}

/**
 * Code with languages associated with them
 * 1) Override Prism sysles for code blocks with language
 */
code[class*="language-"],
pre[class*="language-"] {
  font-family: monospace !important;
}

/*------------------------------------*\
   #LAYOUT
\*------------------------------------*/
/*------------------------------------*\
    #LAYOUT
\*------------------------------------*/
/**
 * Layout Container
 * 1) Caps the width of the content to the maximum width
 *    and centers the container
 */
.l-container {
  max-width: 60rem;
  margin: 0 auto;
}

/**
 * Narrow Layout Container
 * 1) This narrow layout container is for lists, forms,
 *    and other singular objects that aren't dashboard-y
 *    kinds of displays
 */
.l-container--narrow {
  max-width: 36rem;
}

/**
 *
 * 1) This narrow layout container is for lists, forms,
 *    and other singular objects that aren't dashboard-y
 *    kinds of displays
 */
.l-linelength-container {
  max-width: 35rem;
}

/*------------------------------------*\
    #LAYOUT SECTIONS
\*------------------------------------*/
/**
 * Main section
 * 1) The main section is the primary content container
 */
.l-page-layout__main, .l-page-layout__main--blue {
  display: flex;
  height: 100vh;
  -ms-flex-direction: column;
  flex-direction: column;
    /**
	 * Main section of 2 column fixed layout
	 * 1) Margin left equals width of the fixed positioned sidebar
	 */
}
.l-page-layout--two-column-fixed > .l-page-layout__main, .l-page-layout--two-column-fixed > .l-page-layout__main--blue {
    height: 100vh;
}
@media all and (min-width: 62em) {
.l-page-layout--two-column-fixed > .l-page-layout__main, .l-page-layout--two-column-fixed > .l-page-layout__main--blue {
        margin-left: 17rem;
        /* 1 */
}
}
.l-page-layout__main--blue {
  background-color: #e2f1f8;
}

/**
 * Secondary section
 * 1) The secondary section is an auxiliary container
 *    Otherwise known as "sidebar", but we try to
 *    avoid that term to be mindful of the fact in
 *    responsive environments "sidebar"
 */
.l-page-layout__secondary {
  /**
   * Secondary section in a two-column fixed layout
   * 1) This is a fixed element
   */
}
@media all and (min-width: 62em) {
.l-page-layout--two-column-fixed > .l-page-layout__secondary {
      position: fixed;
      z-index: 2;
      overflow: auto;
      width: 17rem;
      height: 100vh;
}
}

/**
* This layout is for a single-scroll, document-style page.
*/
.l-page-layout__document {
  margin: 0 4rem;
  display: flex;
  flex-direction: column;
}

/*------------------------------------*\
    #GRID
\*------------------------------------*/
/**
 * Grid Container
 */
.l-grid, .l-grid--tight {
  margin: 0 -0.5rem;
  display: flex;
  flex-wrap: wrap;
}
@supports (display: grid) {
.l-grid, .l-grid--tight {
      display: grid;
      grid-gap: 16px;
      grid-template-columns: repeat(auto-fill, minmax(285px, 1fr));
      margin: 0;
}
}
@supports (display: grid) {
.l-grid--tight {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
}
}

/**
 * Abstract grid
 * 1) If display: grid is not supported, create evenly distributed grid using display flex
 * 2) Otherwise, display abstract grid
 */
.l-abstract-grid {
  display: flex;
  /* 1 */
  flex-wrap: wrap;
  margin: 0 -0.5rem;
}
@supports (display: grid) {
.l-abstract-grid {
      /* 2 */
      display: grid;
      grid-gap: 8px;
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
      margin: 0;
}
}

/**
 * Grid Item
 */
.l-grid__item {
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  /**
   * Grid item within 3-up grid
   */
  /**
   * Grid item within 3-up grid
   */
  /**
   * Grid item within an abstract grid
   * 1) If grid is not supported, create 3-up grid using display flex
   * 2) If grid is supported, create abstract grid
   * 3) On xl screens, first item spans 2 columns
   * 4) Even items span only 3rd column
   * 5) Multiples of 3 items (3, 6, etc.), span only column 1
   * 6) Multiples of 4 span columns 2 and 3
   */
}
.l-grid--3up .l-grid__item {
    /**
     * 1) If CSS Grid is supported, set the width
     *    of grid__items to 100% to fill space
     *    automatically
     */
}
@media all and (min-width: 35em) {
.l-grid--3up .l-grid__item {
        width: 50%;
}
}
@media all and (min-width: 45em) {
.l-grid--3up .l-grid__item {
        width: 33.33333%;
}
}
@supports (display: grid) {
.l-grid--3up .l-grid__item {
        width: 100%;
        /* 1 */
        padding: 0;
}
}
.l-grid--4up .l-grid__item {
    /**
     * 1) If CSS Grid is supported, set the width
     *    of grid__items to 100% to fill space
     *    automatically
     */
}
@media all and (min-width: 35em) {
.l-grid--4up .l-grid__item {
        width: 50%;
}
}
@media all and (min-width: 55em) {
.l-grid--4up .l-grid__item {
        width: 33.33333%;
}
}
@media all and (min-width: 74em) {
.l-grid--4up .l-grid__item {
        width: 25%;
}
}
@supports (display: grid) {
.l-grid--4up .l-grid__item {
        width: 100%;
        /* 1 */
        padding: 0;
}
}
.l-abstract-grid .l-grid__item {
    /* 1 */
    width: 100%;
    /**
     * 1) If CSS Grid is supported, set the width
     *    of grid__items to 100% to fill space
     *    automatically
     */
}
@media all and (min-width: 35em) {
.l-abstract-grid .l-grid__item {
        width: 50%;
}
}
@media all and (min-width: 45em) {
.l-abstract-grid .l-grid__item {
        width: 33.33333%;
}
}
@supports (display: grid) {
.l-abstract-grid .l-grid__item {
        padding: 0;
        width: 100%;
}
@media all and (min-width: 62em) {
.l-abstract-grid .l-grid__item {
            grid-column: 1 / 3;
            /* 3 */
}
.l-abstract-grid .l-grid__item:nth-child(even) {
              grid-column: 3;
              /* 4 */
}
.l-abstract-grid .l-grid__item:nth-child(3n) {
              grid-column: 1;
              /* 5 */
}
.l-abstract-grid .l-grid__item:nth-child(4n) {
              grid-column: 2/4;
              /* 6 */
}
.l-abstract-grid .l-grid__item:last-child {
              margin-bottom: 0;
}
}
}

/*------------------------------------*\
   #COMPONENTS
\*------------------------------------*/
/*------------------------------------*\
    #ban
\*------------------------------------*/
/**
 * 1) A BAN ("big ass number") is a large numerical display for dashboards.
 */
.c-ban, .c-ban--purple, .c-ban--blue, .c-ban--gold, .c-ban--turquoise {
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1 0 auto;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 3px;
  border: 1px solid #808e95;
  width: 8.25rem;
  height: 7.5rem;
  text-align: center;
  align-content: baseline;
  color: #808e95;
  cursor: pointer;
  transition: 0.15s ease-in-out;
}
.c-ban--white {
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1 0 auto;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 3px;
  border: 1px solid #808e95;
  width: 12.25rem;
  height: 7.5rem;
  text-align: center;
  align-content: baseline;
  color: #212121;
  cursor: pointer;
  transition: 0.15s ease-in-out;
}
.c-ban:hover, .c-ban--purple:hover, .c-ban---white:hover, .c-ban--blue:hover, .c-ban--gold:hover, .c-ban--turquoise:hover, .c-ban:active, .c-ban--purple:active, .c-ban--blue:active, .c-ban--gold:active, .c-ban--turquoise:active, .c-ban---white:active {
    color: #fff;
    background-color: #808e95;
}
.c-ban__body {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

/**
 *  Text within the BAN
  */
.c-ban__number {
  font-family: "Nunito SemiBold", Helvetica, sans-serif;
  font-size: 2.75rem;
  letter-spacing: -.4%;
}
.c-ban__unit {
  font-size: 0.75rem;
  display: inline-flex;
  line-height: 0;
}
.c-ban__label--inside {
  line-height: 0;
  font-family: "Nunito SemiBold", Helvetica, sans-serif;
  font-size: 0.85rem;
  display: block;
  width: 7rem;
}

/**
 *  Label outside BAN
  */
.c-ban__label--outside, .c-ban__label--outside-purple, .c-ban__label--outside-blue, .c-ban__label--outside-gold, .c-ban__label--outside-turquoise {
  font-size: 0.65rem;
  text-transform: uppercase;
  color: #808e95;
}

/**
 *  Color variations
  */
.c-ban--white {
background-color: rgb(255, 255, 255);
border: 1px solid #A8C3B3;
}
.c-ban--white:hover, .c-ban--white:active {
  background-color: #A8C3B3;
}
.c-ban--purple {
  background-color: rgba(202, 100, 204, 0.15);
  border: 1px solid #ca64cc;
}
.c-ban--purple:hover, .c-ban--purple:active {
    background-color: #ca64cc;
}
.c-ban--blue {
  background-color: rgba(39, 149, 251, 0.15);
  border: 1px solid #2795fb;
}
.c-ban--blue:hover, .c-ban--blue:active {
    background-color: #2795fb;
}
.c-ban--gold {
  background-color: rgba(247, 183, 44, 0.15);
  border: 1px solid #f7b72c;
}
.c-ban--gold:hover, .c-ban--gold:active {
    background-color: #f7b72c;
}
.c-ban--turquoise {
  background-color: rgba(66, 190, 168, 0.15);
  border: 1px solid #42BEA8;
}
.c-ban--turquoise:hover, .c-ban--turquoise:active {
    background-color: #42BEA8;
}

/**
 *  Outer label color variations
  */
.c-ban__label--outside-purple {
  color: #ca64cc;
}
.c-ban__label--outside-blue {
  color: #2795fb;
}
.c-ban__label--outside-gold {
  color: #f7b72c;
}
.c-ban__label--outside-turquoise {
  color: #42BEA8;
}

/**
 *  Container for individual BANs
  */
.c-ban__container {
  width: 8.5rem;
  display: inline-block;
}

/*------------------------------------*\
    #BREADCRUMBS
\*------------------------------------*/
/**
 * 1) This is an ordered list showing what level you are on in the site
 */
.c-breadcrumbs {
  display: flex;
  /* 2 */
  flex-wrap: wrap;
  /* 3 */
  margin: 0 0 1rem 0;
  /* 4 */
}

/**
 * Breadcrumbs list item
 * 1) Set to position relative so :after is positioned relative to the breadcrumbs item
 */
.c-breadcrumbs__item {
  position: relative;
  /* 1 */
  margin-right: 1rem;
  /**
   * Icon in between list item
   * 1) Set to position absolute so it is positioned relative to its parent container
   */
  /**
   * Last breadcrumbs list item
   */
}
.c-breadcrumbs__item:after {
    content: "\203A";
    position: absolute;
    /* 1 */
    top: 0;
    right: -10px;
}
.c-breadcrumbs__item:last-child {
    margin-right: 0;
    /**
     * Remove last breadcrumb item icon after
     */
}
.c-breadcrumbs__item:last-child:after {
      display: none;
}

/**
 * Breadcrumbs link
 */
.c-breadcrumbs__link {
  margin: 0 0.5rem;
  /**
   * Breadcrumbs link within first breadcrumb item
   */
}
.c-breadcrumbs__item:first-child .c-breadcrumbs__link {
    margin-left: 0;
}

/*------------------------------------*\
    #BUTTONS
\*------------------------------------*/
/**
 * 1) Button or link that has functionality to it
 * 2) Remove box-shadow that's globally applied to links
 */
.c-btn, .c-btn--reveal, .c-btn--outline, .c-label--filedrop {
  display: inline-block;
  background: #518668;
  border-radius: 3px;
  cursor: pointer;
  color: #fff;
  fill: #fff;
  min-height: 1rem;
  line-height: 1;
  padding: 1rem 1.5rem;
  font-family: "Nunito Bold";
  font-size: 1rem;
  letter-spacing: 1.25%;
  align-items: center;
  border: none;
  box-shadow: none;
  /* 2 */
  text-align: center;
  text-transform: uppercase;
  transition: all 0.15s ease-out;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  /**
   * Button within button group
   */
  /**
   * Button within button group
   */
  /**
   * Button within button group
   */
}
.c-btn:hover, .c-btn--reveal:hover, .c-btn--outline:hover, .c-label--filedrop:hover, .c-btn:target, .c-btn--reveal:target, .c-btn--outline:target, .c-label--filedrop:target {
    background: #22543A;
    color: #fff;
    fill: #fff;
}
.c-btn-group .c-btn, .c-btn-group .c-btn--reveal, .c-btn-group .c-btn--outline, .c-btn-group .c-label--filedrop {
    margin-right: 0.5rem;
}
.c-btn-group .c-btn:last-child, .c-btn-group .c-btn--reveal:last-child, .c-btn-group .c-btn--outline:last-child, .c-btn-group .c-label--filedrop:last-child {
      margin-right: 0;
}
.c-btn-group .c-btn, .c-btn-group .c-btn--reveal, .c-btn-group .c-btn--outline, .c-btn-group .c-label--filedrop {
    margin-right: 0.5rem;
    /**
     * Remove right margin on last button group button
     */
}
.c-btn-group .c-btn:last-child, .c-btn-group .c-btn--reveal:last-child, .c-btn-group .c-btn--outline:last-child, .c-btn-group .c-label--filedrop:last-child {
      margin-right: 0;
}
.c-btn-group--responsive .c-btn, .c-btn-group--responsive .c-btn--reveal, .c-btn-group--responsive .c-btn--outline, .c-btn-group--responsive .c-label--filedrop {
    margin-right: 0;
    margin-bottom: 0.5rem;
}
@media all and (min-width: 24em) {
.c-btn-group--responsive .c-btn, .c-btn-group--responsive .c-btn--reveal, .c-btn-group--responsive .c-btn--outline, .c-btn-group--responsive .c-label--filedrop {
        margin-right: 0.5rem;
        margin-bottom: 0;
        /**
         * Remove right margin on last responsive button group button
         */
}
.c-btn-group--responsive .c-btn:last-child, .c-btn-group--responsive .c-btn--reveal:last-child, .c-btn-group--responsive .c-btn--outline:last-child, .c-btn-group--responsive .c-label--filedrop:last-child {
          margin-right: 0;
}
}

/**
 * Small button
 */
.c-btn--small {
  padding: .375rem .75rem;
}

/*
 * Inner container of the button
 * Allows for display flex on a button
 */
.c-btn__inner {
  display: flex;
  align-items: center;
  justify-content: center;
}
.c-btn--reveal {
  width: 98%;
  padding: .7rem 2rem;
  background-color: #eceff1;
  color: #2795fb;
}
.c-btn--reveal:hover, .c-btn--reveal:target {
    background: rgba(80, 134, 104, 0.5);
}

/*
 * Button icon
 */
.c-btn__icon {
  width: 1rem;
  height: 1rem;
  margin-right: .25rem;
  transition: fill 0.15s ease-in-out;
  /*
   * Button icon within active button
   */
}
.c-btn.is-active .c-btn__icon, .is-active.c-btn--reveal .c-btn__icon, .is-active.c-btn--outline .c-btn__icon, .is-active.c-label--filedrop .c-btn__icon {
    fill: #eceff1;
}
.c-btn__add {
  width: 50px;
  height: 50px;
}
.c-btn__delete {
  width: 1rem;
  height: 1rem;
}

/*------------------------------------*\
    #TEXT BUTTON
\*------------------------------------*/
/**
 * 1) Button that is text only and doesn't contain a border or fill.
 */
.c-text-btn, .c-text-btn--unstyled--light, .c-text-btn--unstyled--dark {
  cursor: pointer;
  color: #518668;
  text-transform: uppercase;
  font-family: "Nunito Bold";
  border-radius: 3px;
  letter-spacing: 1.25%;
  border: none;
  transition: 0.15s ease-in-out;
}
.c-text-btn:hover, .c-text-btn--unstyled--light:hover, .c-text-btn--unstyled--dark:hover, .c-text-btn:focus, .c-text-btn--unstyled--light:focus, .c-text-btn--unstyled--dark:focus {
    color: #22543A;
    transition: 0.15s ease-in-out;
}

/**
 * 2) One step further -- links that look like the surrounding text
 */
.c-text-btn--unstyled--light {
  color: #fff;
}
.c-text-btn--unstyled--dark {
  color: #212121;
}

/* Outline button */
.c-btn--outline, .c-label--filedrop {
  background-color: #fff;
  border: 1px solid #518668;
  color: #518668;
  fill: #518668;
}
.c-btn--outline:hover, .c-label--filedrop:hover, .c-btn--outline:focus, .c-label--filedrop:focus {
    background: rgba(80, 134, 104, 0.5);
    fill: #518668;
    color: #518668;
}

/*------------------------------------*\
    #BUTTON GROUP
\*------------------------------------*/
/**
 * 1) A button group displays buttons side-by-side.
 */
.c-btn-group {
  display: flex;
}

/**
 * 1) A button group displays buttons side-by-side.
 */
.c-btn-group--responsive {
  align-items: center;
  flex-direction: column;
}
@media all and (min-width: 24em) {
.c-btn-group--responsive {
      display: flex;
      align-items: flex-end;
      flex-direction: row;
}
}

/*------------------------------------*\
    #CARD
\*------------------------------------*/
/**
 * 1) A card is a block of media followed by a body of text in a vertical format
 */
.c-card {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  border-radius: 3px;
  position: relative;
  display: flex;
  padding: 2rem;
  border: 0.5px solid rgba(80, 134, 104, 0.5);
  color: #212121;
  background-color: #fff;
}

/**
 * Card Body
 TODO: DONT DO IMPORTANT
 */
.c-card__body {
  flex: 1 0 auto;
}

/**
 * Card Title
 */
.c-card__title {
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  font-family: "Nunito Bold", Helvetica, sans-serif;
  color: #212121;
  margin-bottom: .25rem;
}
.c-card__subtitle {
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-family: "Nunito Bold", Helvetica, sans-serif;
  color: #808e95;
  margin-bottom: .5rem;
}

/**
* Card Footer
*/
.c-card__footer {
  background: #eceff1;
  margin: 1rem -1rem -1rem -1rem;
  padding: 1rem 1.5rem 1rem 1.5rem;
  border-top: 1px solid #eceff1;
}

/**
* Card Actions
* 1) Card actions is a container for links and buttons within a card.
*/
.c-card__actions {
  margin-top: 1rem;
}

/**
 * Card icon
 */
.c-card__icon {
  margin-left: auto;
  transition: transform 0.15s ease-in-out;
  /**
   * Card icon on card hover
   */
}
.c-card:hover .c-card__icon, .c-card:focus .c-card__icon {
    transform: translate(3px);
}

/*------------------------------------*\
    $FOOTER
\*------------------------------------*/
/**
 * 1) Global block at the bottom of each page that contains a navigation and other information
 */
.c-footer {
  text-align: center;
  display: flex;
  flex-shrink: 0;
  color: #22543A;
  border-top: 1px solid #212121;
  padding: 1rem 0;
  margin-top: 1rem;
}
.c-footer--ckm {
  background-color: #22543A;
  color: white;
  margin-top: 0;
  border-top: none;
}
.c-footer__inner {
  padding: 1rem;
  width: 100%;
  text-align: left;
  font-size: .9rem;
}

/*------------------------------------*\
    #FOOTER NAVIGATION
\*------------------------------------*/
/**
 * 1) Navigation within the footer
 */
.c-footer-nav {
  display: flex;
  font-size: 0.85rem;
}

/**
 * Footer navigation link
 */
.c-footer-nav__link {
  display: block;
  padding: 0.5rem 1rem;
  line-height: 1;
  color: #22543A;
}
.c-footer-nav__link:hover, .c-footer-nav__link:focus {
    color: rgba(80, 134, 104, 0.5);
}

/*------------------------------------*\
    #HEADER
\*------------------------------------*/
/**
 * Global block at the top of each page containing the navigation, logo, and other potential contents
 */
.c-header, .c-header-dark, .c-header-ckm {
  position: relative;
}
.c-header {
  border-bottom: 2px solid #eceff1;
  background-color: #fff;
}
.c-header-dark {
  background-color: #212121;
  color: #eceff1;
  border-bottom: none;
}
.c-header-ckm {
  background-color: #22543A;
  color: white;
}

/**
 * Header inner abstract
 */
.c-header__inner, .c-header__inner--slim, .c-header-vertical__inner {
  display: flex;
  align-items: center;
  padding: 1rem;
  background-color: inherit;
}
.c-header__inner--slim {
  padding: .7rem 1.5rem .7rem .7rem;
}
@media all and (min-width: 62em) {
.c-header-vertical__inner {
    flex-direction: column;
    padding: 1rem;
    align-items: flex-start;
    min-height: 100vh;
}
}

/**
 * Header navigation button when collapsed
 */
.c-header__nav-btn, .c-header-vertical__nav-btn {
  background: none;
  width: 2rem;
  fill: #fff;
  border-radius: 5px;
  transition: all 0.15s ease-out;
}
.c-header__nav-btn:hover, .c-header-vertical__nav-btn:hover, .c-header__nav-btn:focus, .c-header-vertical__nav-btn:focus {
    background: none;
    fill: #518668;
}
.c-header__nav-btn {
  margin-left: auto;
  margin-right: 1rem;
}
@media all and (min-width: 46.8em) {
.c-header__nav-btn {
      display: none;
}
}
.c-header-vertical__nav-btn {
  margin-left: auto;
}
@media all and (min-width: 62em) {
.c-header-vertical__nav-btn {
      display: none;
}
}

/**
 * Header navigation container
 * 1) Contains the primary navigation and other possible patterns
 */
.c-header__nav-container, .c-header__nav-container--slim, .c-header-vertical__nav-container {
  display: none;
  width: 100%;
  background-color: inherit;
}
.c-header__nav-container, .c-header__nav-container--slim {
  font: lighter 1rem "Nunito Regular", Helvetica, sans-serif;
}
@media all and (min-width: 46.8em) {
.c-header__nav-container, .c-header__nav-container--slim {
      display: flex;
      align-items: flex-end;
      align-self: flex-end;
}
}
.c-header__nav-container--slim {
  font: lighter 0.85rem "Nunito Regular", Helvetica, sans-serif;
}
@media all and (min-width: 62em) {
.c-header-vertical__nav-container {
    display: block;
    margin-left: 0;
}
}

/**
 * Active header nav container
 * Seems necessary for responsive stuff. now i know.
 */
.c-header__nav-container.js-nav-panel.is-active, .js-nav-panel.is-active.c-header__nav-container--slim,
.c-primary-nav__list.js-nav-panel.is-active, .c-header-vertical__nav-container.js-nav-panel.is-active,
.c-primary-nav-vertical__list.js-nav-panel.is-active {
  display: block;
  position: absolute;
  top: 100%;
  left: 0;
  padding: 0 1rem;
  width: 100%;
  z-index: 5;
}
@media all and (min-width: 46.8em) {
.c-header__nav-container.js-nav-panel.is-active, .js-nav-panel.is-active.c-header__nav-container--slim,
  .c-primary-nav__list.js-nav-panel.is-active {
    position: static;
    padding: 0;
    width: 100%;
    display: inherit;
}
}
@media all and (min-width: 62em) {
.c-header-vertical__nav-container.js-nav-panel.is-active,
  .c-primary-nav-vertical__list.js-nav-panel.is-active {
    position: static;
    padding: 0;
    margin-left: auto;
    width: inherit;
}
}

/**
 * Secondary Navigation
 */
.c-header__secondary {
  border-bottom: 1px solid rgba(80, 134, 104, 0.5);
  float: right;
  background-color: #fff;
}
.c-header__title--secondary {
  font-family: "Nunito SemiBold", Helvetica, sans-serif;
  font-size: 1.4375rem;
  display: inline-flex;
  align-self: baseline;
}
.c-header__secondary--inner {
  display: flex;
}

/*------------------------------------*\
    #HERO
\*------------------------------------*/
/**
 * 1) Block containing headline, description text, that serves as a primary content area
 */
.c-hero {
  display: block;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 100% 100%;
  position: relative;
  margin-bottom: 1rem;
}

/**
 * Hero Image
 */
.c-hero__img {
  display: block;
  width: 100%;
  margin-bottom: 1rem;
}
@media all and (min-width: 29.75em) {
.c-hero__img {
      margin: 0;
}
}

/**
 * Hero Body
 */
.c-hero__body {
  padding: 2rem 1rem;
  /**
   * Blue tinted hero
   */
  /**
   * Red tinted hero
   */
}
.c-hero--blue-tint .c-hero__body {
    background: rgba(0, 0, 100, 0.4);
}
.c-hero--red-tint .c-hero__body {
    background: rgba(100, 0, 0, 0.4);
}
@media all and (min-width: 46.8em) {
.c-hero__body {
      padding: 4rem 2rem;
}
}
@media all and (min-width: 62em) {
.c-hero__body {
      padding: 5rem;
}
}

/**
 * Hero Title
 */
.c-hero__title {
  max-width: 48rem;
  margin: 0 0 1rem auto;
  font-size: 2rem;
  text-align: right;
}
@media all and (min-width: 46.8em) {
.c-hero__title {
      margin: 0 0 2rem auto;
      font-size: 3rem;
}
}

/**
 * Hero Description
 */
.c-hero__desc {
  margin-bottom: 2rem;
  margin-left: auto;
  max-width: 34rem;
  text-align: right;
}
@media all and (min-width: 46.8em) {
.c-hero__desc {
      font-size: 1.25rem;
}
}

/*------------------------------------*\
    #LOGO
\*------------------------------------*/
/**
 * Branding image or text of the site
 */
.c-logo, .c-logo-vertical {
  display: flex;
  align-items: flex-end;
  max-width: 15.75rem;
}
@media all and (min-width: 39.8em) {
.c-logo, .c-logo-vertical {
      min-width: 5rem;
      margin: 0;
}
}
@media all and (min-width: 62em) {
.c-logo-vertical {
    min-width: 4rem;
    margin: 0 0 2rem 0;
    width: 100%;
    display: block;
}
}
.c-logo__img {
  display: block;
  width: 4.5rem;
}
.c-logo__title {
  font: normal 1.1rem "Nunito Bold", Helvetica, sans-serif;
  font-size: 1.4375rem;
  color: #22543A;
  white-space: nowrap;
  margin: 0 1rem;
}
@media all and (min-width: 62em) {
.c-logo__title {
      margin: 0;
}
}
.c-logo--small {
  display: block;
  width: 2rem;
}

/*------------------------------------*\
    #LIST
\*------------------------------------*/
/**
 * 1) A list is a collection of short items, ordered or unordered
 */
.c-list--unordered li, .c-list--ordered li {
  line-height: 1.4;
  margin: 0.75rem 0;
}
.c-list--unordered {
  padding-left: 1rem;
  list-style: none;
}
.c-list--unordered li {
    padding-left: 1rem;
}
.c-list--ordered {
  counter-reset: custom-counter;
}
.c-list--ordered li {
    counter-increment: custom-counter;
}
.c-list--ordered li::before {
    content: counter(custom-counter) ". ";
    padding-right: 1.25rem;
}
.c-list--unordered_line1 {
  font-family: "Nunito SemiBold", Helvetica, sans-serif;
  font-size: 1.2rem;
  line-height: 1.25;
}
.c-list--unordered_line2 {
  color: #808e95;
}

/*------------------------------------*\
    #ICON
\*------------------------------------*/
/**
 * 1) Small image that represents functionality
 */
.c-icon {
  height: 16px;
  width: 16px;
}
.c-icon--large {
  height: 25px;
  width: 25px;
}
.c-icon--add {
  height: 20px;
  width: 20px;
}
.c-icon--delete {
  height: 12px;
  width: 12px;
}
.c-icon--sort {
  height: .75rem;
  width: .75rem;
}
.c-icon--edit {
  height: .75rem;
  width: .75rem;
}

/*------------------------------------*\
  #MESSAGING
\*------------------------------------*/
/**
 * Error message
 */
.c-error {
  border-radius: 3px;
  background-color: rgba(251, 65, 65, 0.25);
  color: #212121;
  display: inline-block;
  padding: 0.3rem 1rem;
  font-size: 0.85rem;
  margin: 0 0 0.5rem;
}
.c-tooltip__trigger, .c-tooltip__trigger--dotted {
  position: relative;
}
.c-tooltip__trigger:hover .c-tooltip, .c-tooltip__trigger--dotted:hover .c-tooltip {
    visibility: visible;
}
.c-tooltip__trigger--dotted {
  border-bottom: 1px dotted rgba(80, 134, 104, 0.5);
  display: inline-block;
}
.c-tooltip {
  visibility: hidden;
  /* semi-opaque color-dark */
  background-color: rgba(33, 33, 33, 0.8);
  color: #fff;
  text-align: center;
  padding: 0.3rem;
  border-radius: 3px;
  font-size: 0.75rem;
  max-width: 10rem;
  /* Position the tooltip text */
  white-space: pre-wrap;
  position: absolute;
  z-index: 1;
  left: 105%;
  top: 0;
}
.c-status-banner {
  width: 100%;
  background-color: #e2f1f8;
  color: #808e95;
  text-align: center;
  font-size: 1.2rem;
  padding: 1rem;
}

/*------------------------------------*\
    #PAGE HEADER
\*------------------------------------*/
/**
 * 1) Container that consists of of a page header title and description
 */
/**
 * Page header (dark version)
 */
.c-page-header--hero {
  padding: 4rem 0 2rem;
  line-height: 1.5;
}

/**
 * Page header title
 */
.c-page-header__title {
  margin-bottom: 0.4rem;
}

/**
 * Give a little more breathing room for "hero" style headers.
   If multiple items (e.g., image) ensure they're centered
 */
.c-page-header__title--hero {
  margin-bottom: 1.75rem;
  display: flex;
  align-items: flex-end;
}
.c-page-header__title__img {
  padding-right: 1.5rem;
}

/**
 * Page description
 */
.c-page-header__desc {
  margin-bottom: 2rem;
  font-size: 1.2rem;
  max-width: 36rem;
}

/*------------------------------------*\
    #PRIMARY NAVIGATION
\*------------------------------------*/
/**
 * Primary navigation most likely existing in the header
 */
/* TODO: create whatever necessary class for horiz */
.c-primary-nav-vertical {
  margin-left: -1rem;
  margin-right: -1rem;
}

/**
 * Primary navigation list
 */
.c-primary-nav-vertical__list, .c-primary-nav__list-left, .c-primary-nav__list-right {
  flex-direction: column;
  display: block;
}
@media all and (min-width: 62em) {
.c-primary-nav-vertical__list {
    flex-direction: row;
}
}
@media all and (min-width: 46.8em) {
.c-primary-nav__list-left, .c-primary-nav__list-right {
    flex-direction: row;
}
}
.c-primary-nav__list-right {
  margin-left: auto;
}

/**
 * Primary navigation item
 */
.c-primary-nav__item {
  position: relative;
  /* for absolutely positioned submenus */
}
@media all and (min-width: 46.8em) {
.c-primary-nav__item {
      max-width: 20%;
      display: inline;
      margin-left: auto;
}
}
.c-primary-nav-vertical__item {
  /* i think i got rid of a position:relative here */
}
@media all and (min-width: 62em) {
.c-primary-nav-vertical__item {
      margin-right: 1rem;
      /**
       * Remove margin right from last primary navigation item on large screens
       */
}
.c-primary-nav-vertical__item:last-child {
        margin-right: 0;
}
}

/**
 * Primary navigation link
 */
.c-primary-nav-vertical__link, .c-primary-nav__link {
  display: block;
  padding: 0.5rem 1rem;
  cursor: pointer;
  /* overwrite default link styles based on containing header color */
}
.c-header .c-primary-nav-vertical__link, .c-header .c-primary-nav__link {
    color: #212121;
}
.c-header-dark .c-primary-nav-vertical__link, .c-header-dark .c-primary-nav__link, .c-header-ckm .c-primary-nav-vertical__link, .c-header-ckm .c-primary-nav__link {
    color: #b0bec5;
}
.c-primary-nav-vertical__link:focus, .c-primary-nav__link:focus {
    font-family: "Nunito Bold", Helvetica, sans-serif;
    color: #518668;
}
.c-primary-nav-vertical__link.is-active, .is-active.c-primary-nav__link {
    font-family: "Nunito Bold", Helvetica, sans-serif;
    color: #fff;
}
.c-primary-nav-vertical__link:hover, .c-primary-nav__link:hover {
    color: #518668;
    transition: ease .1s;
}
.c-primary-nav__link {
  color: #808e95;
}
@media all and (min-width: 46.8em) {
.c-primary-nav__link {
      display: inline;
      padding-bottom: 0;
}
}
.c-primary-nav__link:focus {
    font-family: "Nunito Bold", Helvetica, sans-serif;
    color: rgba(80, 134, 104, 0.5);
}
.c-primary-nav__link.is-active {
    font-family: "Nunito Bold", Helvetica, sans-serif;
    color: #518668;
}
.c-primary-nav__link:hover {
    color: rgba(80, 134, 104, 0.5);
    transition: ease .1s;
}

/**
  * Nested item menus with subnavigation
  */
.c-primary-nav-vertical__link--has-children {
  display: flex;
  align-items: center;
}
@media all and (min-width: 62em) {
.c-primary-nav-vertical__item.is-active .c-primary-nav-vertical__link--has-children {
    background: #212121;
    color: #eceff1;
}
}
.c-primary-nav-vertical__item.is-active .c-primary-nav-vertical__link--has-children {
  background: none;
  color: inherit;
}
.c-primary-nav__item--has-children {
  position: relative;
}

/**
 * Primary navigation list subnavigation
 * Targets descendant of the
 */
.c-primary-nav__subitem {
  /**
   * Primary navigation sublist within subitem
   */
  /**
   * Primary navigation sublist within active subitem
   */
}
@media all and (min-width: 46.8em) {
.c-primary-nav__subitem a:hover {
      background-color: rgba(80, 134, 104, 0.5);
      color: #212121;
}
}
.c-primary-nav__subitem .c-primary-nav__subitem {
    display: none;
}
.c-primary-nav__subitem.is-active .c-primary-nav__subitem {
    display: block;
}
.c-primary-nav__sublist, .c-primary-nav-vertical__sublist {
  border-left: 1px solid #eceff1;
}

/**
 * Primary navigation sublist
 */
/**
 * Primary navigation list subnavigation first of type
 * 1) First of type is chosen to only choose direct descendant of primary
 *    navigation item
 */
.c-primary-nav__sublist:first-of-type {
  display: none;
  margin-left: 1rem;
  /**
   * Primary navigation sublist within active primary navigation item
   */
}
@media all and (min-width: 46.8em) {
.c-primary-nav__sublist:first-of-type {
      margin-left: 0;
}
}
.c-primary-nav__item.is-active .c-primary-nav__sublist:first-of-type {
    display: block;
    /* different margins for header heights */
}
@media all and (min-width: 46.8em) {
.c-header__inner .c-primary-nav__item.is-active .c-primary-nav__sublist:first-of-type, .c-header__inner--slim .c-primary-nav__item.is-active .c-primary-nav__sublist:first-of-type, .c-header-vertical__inner .c-primary-nav__item.is-active .c-primary-nav__sublist:first-of-type, .c-header__inner--slim .c-primary-nav__item.is-active .c-primary-nav__sublist:first-of-type {
        background: #fff;
        color: #212121;
        position: absolute;
        z-index: 6;
        left: 0;
        border: 1px solid #eceff1;
        top: 100%;
        margin-top: 1rem;
        width: 100%;
}
}
@media all and (min-width: 46.8em) {
.c-header__inner--slim .c-primary-nav__item.is-active .c-primary-nav__sublist:first-of-type {
        margin-top: .7rem;
}
}
.c-primary-nav-vertical__sublist:first-of-type {
  display: none;
  margin-left: 1rem;
  /**
   * Primary navigation sublist within active primary navigation item
   */
}
@media all and (min-width: 62em) {
.c-primary-nav-vertical__sublist:first-of-type {
      margin-left: 0;
}
}
.is-active > .c-primary-nav-vertical__sublist:first-of-type {
    display: block;
}
.c-primary-nav-vertical__item.is-active .c-primary-nav-vertical__sublist:first-of-type {
    display: block;
    background: none;
}
@media all and (min-width: 62em) {
.c-primary-nav-vertical__item.is-active .c-primary-nav-vertical__sublist:first-of-type {
        margin: 0;
        margin-left: 1rem;
        padding: 0;
        position: static;
}
}

/**
 * Primary navigation sublink
 */
.c-primary-nav__sublink, .c-primary-nav-vertical__sublink {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  color: inherit;
}
.c-primary-nav__sublink:focus, .c-primary-nav-vertical__sublink:focus {
    outline: 1px dotted rgba(80, 134, 104, 0.5);
}
@media all and (min-width: 46.8em) {
.c-primary-nav__sublink, .c-primary-nav-vertical__sublink {
      border: 0.5px solid rgba(80, 134, 104, 0.5);
}
}
@media all and (min-width: 62em) {
.c-primary-nav-vertical__sublink {
    color: inherit;
}
}

/**
 * Primary navigation sublink with subnavigation
 */
.c-primary-nav__sublink--has-children,
.c-primary-nav-vertical__sublink--has-children {
  display: flex;
  align-items: center;
}

/**
 * Primary navigation icon
 */
.c-primary-nav__icon, .c-primary-nav-vertical__icon, .c-primary-nav__icon--light {
  fill: #eceff1;
  transition: fill 0.2s ease;
  margin-left: auto;
  float: right;
  align-self: baseline;
  transform: rotate(-90deg);
}
.c-primary-nav__item.is-active .c-primary-nav__icon {
  fill: rgba(80, 134, 104, 0.5);
  transform: none;
}
@media all and (min-width: 46.8em) {
.c-primary-nav__icon {
    transform: none;
}
.c-primary-nav__item.is-active .c-primary-nav__icon {
      transform: rotate(180deg);
}
}
.c-primary-nav__link:hover .c-primary-nav__icon, .c-primary-nav__link:focus .c-primary-nav__icon {
  fill: rgba(80, 134, 104, 0.5);
}
.c-primary-nav-vertical__item.is-active .c-primary-nav-vertical__icon {
  fill: rgba(80, 134, 104, 0.5);
  transform: none;
}
.c-primary-nav__icon--light {
  fill: #518668;
}
.c-primary-nav__icon--light:hover {
    fill: rgba(80, 134, 104, 0.5);
}

/**
 * Primary navigation subicon
 */
.c-primary-nav__subicon, .c-primary-nav-vertical__subicon {
  fill: #eceff1;
  transition: fill 0.2s ease;
  margin-left: auto;
}
.c-primary-nav__sublink:hover .c-primary-nav__subicon, .c-primary-nav-vertical__sublink:hover .c-primary-nav__subicon, .c-primary-nav__sublink:hover .c-primary-nav-vertical__subicon, .c-primary-nav-vertical__sublink:hover .c-primary-nav-vertical__subicon, .c-primary-nav__sublink:focus .c-primary-nav__subicon, .c-primary-nav-vertical__sublink:focus .c-primary-nav__subicon, .c-primary-nav__sublink:focus .c-primary-nav-vertical__subicon, .c-primary-nav-vertical__sublink:focus .c-primary-nav-vertical__subicon {
    fill: rgba(80, 134, 104, 0.5);
}
.c-primary-nav__subicon {
  /**
   * Primary navigation subicon within active link
   */
}
.c-primary-nav__subitem.is-active .c-primary-nav__subicon {
    fill: #eceff1;
    transform: rotate(180deg);
}
.c-primary-nav-vertical__subicon {
  /**
   * Primary navigation subicon within active subitem within vertical header
   */
}
.c-primary-nav__subitem.is-active .c-primary-nav-vertical__subicon {
    fill: rgba(80, 134, 104, 0.5);
    transform: rotate(90deg);
}

/*------------------------------------*\
    #SECTION
\*------------------------------------*/
/**
 * Section block that helps chunk related items together
 */
.c-section {
  margin-bottom: 2.5rem;
}

/*------------------------------------*\
    #SECTION HEADER
\*------------------------------------*/
/**
 * 1) Block containing section title and description to distinguish section
 */
.c-section__header {
  padding: 0.5rem 0 1rem;
  display: block;
}
.c-section__header__img {
  max-height: 2.5rem;
  padding-right: 1rem;
}

/**
 * 1) border block for more segmented pages
 */
.c-section__header--border {
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #212121;
}
.c-section__header--border .c-section-header__title {
    font-size: 1.4375rem;
}

/*------------------------------------*\
    #SECTION HIGHLIGHT
\*------------------------------------*/
/**
 * 1) Block differently colored to set aside section
 */
.c-section--highlighted {
  padding: 0.1rem 0 1.5rem;
  display: block;
  background-color: rgba(226, 241, 248, 0.8);
  color: #212121 !important;
}
.c-section--dark {
  padding: 0.1rem 0 1.5rem;
  display: block;
  background-color: #212121;
  color: #eceff1 !important;
}

/*------------------------------------*\
    #SECTION WEDGE
\*------------------------------------*/
/**
 * 1) Angled text blocks included for impact
 */
/**
 * before and after blocks are essentially invisible until
 * particular border widths are added
 */
.c-section--wedge p {
  margin-bottom: 0;
}
.c-section--wedge::before {
  left: 0;
  content: " ";
  display: block;
  width: 100%;
  border-style: solid;
  border-width: 0;
  backface-visibility: hidden;
}
.c-section--wedge::after {
  left: 0;
  content: " ";
  display: block;
  width: 100%;
  border-style: solid;
  border-width: 0;
  backface-visibility: hidden;
}
.c-section--wedge--acute-left-top::before {
  border-width: 0 100vw 3rem 0;
}
.c-section--wedge--left-top::before {
  border-width: 0 100vw 1rem 0;
}
.c-section--wedge--acute-right-top::before {
  border-width: 0 0 3rem 100vw;
}
.c-section--wedge--right-top::before {
  border-width: 0 0 1rem 100vw;
}
.c-section--wedge--acute-left-bottom::after {
  border-width: 3rem 100vw 0 0;
}
.c-section--wedge--left-bottom::after {
  border-width: 1rem 100vw 0 0;
}
.c-section--wedge--acute-right-bottom::after {
  border-width: 3rem 0 0 100vw;
}
.c-section--wedge--right-bottom::after {
  border-width: 1rem 0 0 100vw;
}
.c-section--wedge--dark {
  background: #212121;
}
.c-section--wedge--dark::before {
    border-color: transparent transparent #212121;
}
.c-section--wedge--dark::after {
    border-color: #212121 transparent transparent;
}
.c-section--wedge--light {
  background: #fff;
}
.c-section--wedge--light::before {
    border-color: transparent transparent #fff;
}
.c-section--wedge--light::after {
    border-color: #fff transparent transparent transparent;
}

/*------------------------------------*\
	#TABLE
\*------------------------------------*/
/**
 * 1) Data Table
 */
.c-table_body {
  max-width: 60%;
}

/**
 * Table Header
 */
.c-table__header {
  background: rgba(176, 190, 197, 0.4);
  font-family: "Nunito Bold", Helvetica, sans-serif;
}
.c-table__header th {
    padding: .5rem 1rem;
}

/**
 * Table Header Cell
 */
.c-table__header-cell {
  padding-left: 20px;
}
.c-table__th, .c-table__td {
  padding-left: 20px;
  padding-right: 20px;
}

/**
 * Table Row
 */
.c-table__row {
  font-family: "Nunito Regular", Helvetica, sans-serif;
  font-size: 0.85rem;
  border-bottom: 0.5px #b0bec5;
}
.c-table__row td {
    padding: .75rem 1rem;
}

/**
 * Color variations
 */
.c-table__base-header {
  background-color: rgba(176, 190, 197, 0.4);
}
.c-table__base-row {
  background-color: rgba(176, 190, 197, 0.1);
}
.c-table__lavender-header {
  background-color: rgba(202, 168, 244, 0.4);
}
.c-table__lavender-row {
  background-color: rgba(202, 168, 244, 0.1);
}
.c-table__blue-header {
  background-color: rgba(39, 149, 251, 0.4);
}
.c-table__blue-row {
  background-color: rgba(39, 149, 251, 0.1);
}
.c-table__purple-header {
  background-color: rgba(202, 100, 204, 0.4);
}
.c-table__purple-row {
  background-color: rgba(202, 100, 204, 0.1);
}
.c-table__dark-gold-header {
  background-color: rgba(247, 183, 44, 0.4);
}
.c-table__dark-gold-row {
  background-color: rgba(247, 183, 44, 0.1);
}
.c-table__body {
  border-bottom: 1px solid rgba(176, 190, 197, 0.4);
}
tr.c-table__base-row:nth-child(odd) {
  background-color: #fff;
}
tr.c-table__lavender-row:nth-child(odd) {
  background-color: #fff;
}
tr.c-table__blue-row:nth-child(odd) {
  background-color: #fff;
}
tr.c-table__purple-row:nth-child(odd) {
  background-color: #fff;
}
tr.c-table__dark-gold-row:nth-child(odd) {
  background-color: #fff;
}

/**
 * Table Cell
 */
.c-table__cell {
  padding: 5px 0.8rem;
}
.c-table__delete-cell {
  padding-right: 5px;
  width: 2rem;
}

/**
 * Lightweight table
 */
.c-table_body--light {
  max-width: 40%;
}
.c-table__header--light {
  background-color: none;
  font-family: "Nunito Bold", Helvetica, sans-serif;
  border-bottom: 1.25px solid rgba(80, 134, 104, 0.5);
}
.c-table__header--light th {
    padding: .1rem 0;
}
.c-table__header-cell--light {
  padding-left: .5rem;
}
.c-table__th--light, .c-table__td--light {
  padding-left: 0;
  padding-right: 0;
}
.c-table__row--light {
  font-family: "Nunito Regular", Helvetica, sans-serif;
  font-size: 0.85rem;
}
.c-table__row--light td {
    padding: .75rem 0;
}

/**
 * Table Add-ons
 */
.c-table__edit {
  padding-left: 5px;
  width: 2rem;
}
.c-table__edit-icon {
  height: 1rem;
  width: 1rem;
  position: relative;
  vertical-align: middle;
  transition: .2s ease;
  opacity: .6;
}
.c-table__edit-icon:hover {
  opacity: 1;
}
.c-table__edit-icon a:focus {
  opacity: 1;
}
.c-table__delete {
  width: 1rem;
  height: 1rem;
  position: relative;
  vertical-align: middle;
}
.c-table__add {
  font-family: "Nunito Regular", Helvetica, sans-serif;
  width: 1.75rem;
  height: 1.75rem;
  padding-right: .75rem;
  position: relative;
  vertical-align: middle;
  background-color: #fff;
  font-size: 0.85rem;
}
.c-table__title {
  font-family: "Nunito Bold", Helvetica, sans-serif;
  font-size: 1.4375rem;
  padding: 1rem;
  padding-bottom: .5rem;
  text-align: left;
}
.c-table__description {
  font-size: 0.85rem;
  font-family: "Nunito SemiBold", Helvetica, sans-serif;
  color: #808e95;
  text-align: left;
  padding: 0 1rem .5rem 1rem;
  max-width: 75%;
}
.c-table__records {
  text-align: center;
  font-family: "Nunito Regular", Helvetica, sans-serif;
  font-size: 0.85rem;
}
.c-table__filter {
  font-family: "Nunito Regular", Helvetica, sans-serif;
  font-size: 0.85rem;
  display: flex;
  align-items: center;
  margin: 1.5rem 0 1.85rem .5rem;
}
.c-table__search {
  font-family: "Nunito Regular", Helvetica, sans-serif;
  opacity: .65;
  padding-right: 1rem;
  width: 10rem;
  background-color: rgba(226, 241, 248, 0.8);
  border-style: solid;
  border-width: 1px;
  border-color: rgba(80, 134, 104, 0.5);
  border-radius: 3px;
  margin-right: 3.5rem;
  height: 1.5rem;
}
.c-table__record-display {
  font-family: "Nunito Regular", Helvetica, sans-serif;
  font-size: 0.85rem;
  height: 1.5rem;
  opacity: .65;
  width: 1.25rem;
  padding-right: 3rem;
  background-color: #eceff1;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(80, 134, 104, 0.5);
  border-radius: 3px;
  margin-left: .5rem;
  display: inline;
}
.c-table__record-display-label {
  padding: 0 .5rem 0 .5rem;
}

/**
 * Table Pagination
 */
.c-table__pagination {
  font-family: "Nunito Regular", Helvetica, sans-serif;
  display: inline-block;
  text-align: center;
}
.c-table__pagination a {
  font-family: "Nunito Regular", Helvetica, sans-serif;
  font-size: 0.75rem;
  color: rgba(33, 33, 33, 0.6);
  padding: 6px 12px;
  transition: background-color .3s;
  border: 1px solid #e2f1f8;
  border-radius: 4px;
}
.c-table__pagination a.active {
  background-color: #e2f1f8;
  color: #212121;
  border: 1px solid #b0bec5;
}
.c-table__pagination a:hover:not(.active) {
  background-color: rgba(226, 241, 248, 0.8);
}

/**
 * Table sorting
 */
.c-table__sort {
  height: .75rem;
  width: .75rem;
  vertical-align: middle;
  display: inline-flex;
  margin-left: .75rem;
  transition: .2s ease;
  opacity: .6;
}
.c-table__sort:hover {
  opacity: 1;
}
.c-table__sort a:focus {
  opacity: 1;
}

/*------------------------------------*\
    #TABS
\*------------------------------------*/
/**
 * 1) Unordered list of links where the each link targets different information
 */
/**
 * Vertical tabs
 * 1) Displays tabs on the right and the body on the left
 */
.c-tabs--vertical {
  display: flex;
  margin-bottom: 1rem;
}

/**
 * Tabs list
 */
.c-tabs__list {
  display: flex;
  border-bottom: 1px solid #eceff1;
  /**
   * Tabs list within vertical tabs
   */
}
.c-tabs--vertical .c-tabs__list {
    border: 1px solid #eceff1;
    display: block;
    order: 1;
    border-bottom: 0;
}

/**
* Tabs list
* 1) Overlap the tabs bottom border
*/
.c-tabs__link {
  display: block;
  padding: 0.5rem 1rem;
  margin-bottom: -1px;
  min-width: 5rem;
  text-align: center;
  /**
   * Active tabs link
   */
  /**
   * Tabs link within vertical tabs
   */
}
.c-tabs__link.is-active {
    border: 1px solid #eceff1;
    border-bottom: 1px solid #fff;
}
.c-tabs--vertical .c-tabs__link {
    border: 0;
    border-bottom: 1px solid #eceff1;
    /**
     * Last tabs item link within vertical tabs
     */
    /**
     * Active tabs link within vertical tabs
     */
}
.c-tabs--vertical .c-tabs__link.is-active {
      background: #eceff1;
}

/**
 * Tab Body
 */
.c-tabs__body {
  padding: 1rem 0;
  border-top: 0;
  /**
   * Tabs body within vertical tabs
   * 1) Take up the remaining space that the actual tabs do not take up
   */
}
.c-tabs--vertical .c-tabs__body {
    flex: 1;
    /* 1 */
    border: 1px solid #eceff1;
    border-right: 0;
}

/**
 * Tab Panel
 * 1) Display by default, and only hide if JS is available
 */
.c-tabs__panel {
  display: none;
  padding: 1rem;
  /**
   * Active tabs panel
   */
}
.c-tabs__panel.is-active {
    display: block;
}

/*------------------------------------*\
    #TEXT PASSAGE
\*------------------------------------*/
/**
 * 1) A passage of text, including various components (i.e. article, blog post)
 */
.c-text-passage {
  color: #212121;
  /**
   * Link within the text passage
   */
  /**
   * Blockquote within text passage
   */
  /**
   * First-level heading within text passage
   */
  /**
   * Second-level heading within text passage
   */
  /**
   * Third-level heading within text passage
   */
  /**
   * Fourth-level heading within text passage
   */
  /**
   * Fifth-level heading within text passage
   */
  /**
   * Sixth-level heading within text passage
   */
  /**
   * Unordered list within text passage
   */
  /**
   * Ordered list within text passage
   */
}
.c-text-passage p {
    margin-bottom: 1rem;
}
.c-text-passage a:not(.c-btn):not(.c-btn--reveal):not(.c-btn--outline):not(.c-label--filedrop) {
    text-decoration: underline;
}
.c-text-passage a:not(.c-btn):not(.c-btn--reveal):not(.c-btn--outline):not(.c-label--filedrop):hover, .c-text-passage a:not(.c-btn):not(.c-btn--reveal):not(.c-btn--outline):not(.c-label--filedrop):focus {
      color: #212121;
}
.c-text-passage blockquote {
    padding-left: 0.8rem;
    border-left: 3px solid #212121;
    font-size: 1rem;
}
.c-text-passage h1 {
    margin-bottom: 1rem;
}
.c-text-passage h2 {
    margin: 2rem 0 0.5rem;
}
.c-text-passage h3 {
    margin: 1rem 0 1rem;
}
.c-text-passage h4 {
    margin: 1rem 0 1rem;
}
.c-text-passage h5 {
    margin: 1rem 0 1rem;
}
.c-text-passage h6 {
    margin: 1rem 0 1rem;
}
.c-text-passage ul {
    margin-left: 1rem;
    margin-bottom: 1rem;
}
.c-text-passage ul li:last-child {
      margin-bottom: 0;
}
.c-text-passage ol {
    margin-left: 1rem;
    margin-bottom: 1rem;
}
.c-text-passage ol li:last-child {
      margin-bottom: 0;
}
.c-text-passage li {
    margin-bottom: 0.5rem;
    line-height: 1.6;
}

/*------------------------------------*\
    #TILE
\*------------------------------------*/
/**
 * 1) A block of stylized content containing a key and a value
 * 2) The root of a tile is typically <a> tag
 */
.c-tile {
  display: block;
  align-items: center;
  padding: 1.5rem;
  background: #212121;
  color: #fff;
}

/**
 * Tile headline
 * 1) Headline of the tile
 */
.c-tile__headline {
  display: block;
  font-size: 1.4375rem;
}

/*------------------------------------*\
    #TILE LIST
\*------------------------------------*/
.c-input--text, .c-input--white, .c-input--textarea, .c-input--select, .c-input--select--dark, .c-input--number {
  /* font: 1.25rem $font; */
  max-width: 35vw;
  border-radius: 3px;
  background-color: rgba(226, 241, 248, 0.8);
  border: 1px solid rgba(80, 134, 104, 0.5);
  transition: all 0.1s ease-in-out;
  color: #212121;
  font: 1rem "Nunito Regular", Helvetica, sans-serif;
  line-height: 1.5rem;
  /* hide default glow and replace with green */
}
.c-input--text:focus, .c-input--white:focus, .c-input--textarea:focus, .c-input--select:focus, .c-input--select--dark:focus, .c-input--number:focus {
    outline: none !important;
    border: 1px solid rgba(80, 134, 104, 0.5) !important;
    box-shadow: 0 0 10px rgba(80, 134, 104, 0.5);
}
.c-input--text:disabled, .c-input--white:disabled, .c-input--textarea:disabled, .c-input--select:disabled, .c-input--select--dark:disabled, .c-input--number:disabled {
    background-color: #ccc;
    opacity: 0.3;
}
.c-input--text.alert, .alert.c-input--white, .alert.c-input--textarea, .alert.c-input--select, .alert.c-input--select--dark, .alert.c-input--number {
    border: none;
    box-shadow: 0 0 10px #FB4141;
}
.c-input--white {
  background-color: #fff;
}
.c-input--white:disabled {
    opacity: .3;
}
.c-input--textarea {
  min-height: 10rem;
}

/* radio button styles */
.c-input--radio, .c-input--checkbox {
  /* hide default radio */
  position: absolute;
  z-index: -1;
  opacity: 0;
  /*make it circular */
  border-radius: 50%;
}
.c-input--radio__indicator, .c-input--checkbox__indicator {
  /* this is the outer circle we actually see */
  position: absolute;
  top: .45rem;
  left: 0;
  width: 1.25rem;
  height: 1.25rem;
  background: #fff;
  border: 1px solid rgba(80, 134, 104, 0.5);
  /* the after psuedo element actually has the green circle for selection */
}
.c-label--radio .c-input--radio__indicator, .c-label--checkbox .c-input--radio__indicator, .c-label--radio .c-input--checkbox__indicator, .c-label--checkbox .c-input--checkbox__indicator {
    border-radius: 50%;
}
.c-label--radio .c-input--radio__indicator:after, .c-label--checkbox .c-input--radio__indicator:after, .c-label--radio .c-input--checkbox__indicator:after, .c-label--checkbox .c-input--checkbox__indicator:after {
    position: absolute;
    display: block;
    content: "";
    top: .25rem;
    left: .25rem;
    width: .625rem;
    height: .625rem;
    border-radius: 50%;
    background: rgba(80, 134, 104, 0.5);
    opacity: 0;
    transition: opacity 0.3s;
}

/* pseudo states for selection */
.c-label--radio, .c-label--checkbox {
  cursor: pointer;
  /* relative because input and controller children are absolute */
  position: relative;
  padding: .25rem 0 .25rem 2.5rem;
  display: block;
  /* grey glow on hover */
  /* checked state */
  /* low opacity in disabled */
}
.c-label--radio:hover input ~ .c-input--radio__indicator, .c-label--checkbox:hover input ~ .c-input--radio__indicator, .c-label--radio:hover input ~ .c-input--checkbox__indicator, .c-label--checkbox:hover input ~ .c-input--checkbox__indicator, .c-label--radio input:focus ~ .c-input-radio__indicator, .c-label--checkbox input:focus ~ .c-input-radio__indicator {
    background: rgba(226, 241, 248, 0.8);
}
.c-label--radio input:checked ~ .c-input--radio__indicator, .c-label--checkbox input:checked ~ .c-input--radio__indicator, .c-label--radio input:checked ~ .c-input--checkbox__indicator, .c-label--checkbox input:checked ~ .c-input--checkbox__indicator {
    background: #fff;
}
.c-label--radio input:checked ~ .c-input--radio__indicator:after, .c-label--checkbox input:checked ~ .c-input--radio__indicator:after, .c-label--radio input:checked ~ .c-input--checkbox__indicator:after, .c-label--checkbox input:checked ~ .c-input--checkbox__indicator:after {
    opacity: 1;
}
.c-label--radio input:disabled ~ .c-input--radio__indicator, .c-label--checkbox input:disabled ~ .c-input--radio__indicator, .c-label--radio input:disabled ~ .c-input--checkbox__indicator, .c-label--checkbox input:disabled ~ .c-input--checkbox__indicator {
    pointer-events: none;
    opacity: .6;
    background: #ccc;
}

/* CHECKBOXES - not dissimilar from radio buttons, just lessen border-radius */
.c-input--checkbox {
  border-radius: 3px;
}
.c-input--checkbox__indicator {
  /* low opacity in disabled */
}
.c-label--checkbox .c-input--checkbox__indicator {
    border-radius: 3px;
}
.c-label--checkbox .c-input--checkbox__indicator:after {
    border-radius: 2px;
}
.c-input--checkbox__indicator input:disabled ~ .c-input--checkbox__indicator {
    pointer-events: none;
    opacity: .6;
    background: rgba(176, 190, 197, 0.5);
}

/* TOGGLE SWITCH */
.c-input--switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 28px;
}

/* Hide default HTML checkbox */
.c-input--switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.c-input--switch__slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #b0bec5;
  -webkit-transition: .4s;
  transition: .4s;
}
.c-input--switch__slider:before {
  position: absolute;
  content: "";
  height: 22px;
  width: 22px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}
input:checked + .c-input--switch__slider {
  background-color: #518668;
}
input:checked + .c-input--switch__slider:before {
  -webkit-transform: translateX(22px);
  -ms-transform: translateX(22px);
  transform: translateX(22px);
}

/* Rounded sliders */
.c-input--switch__slider.c-input--switch__round {
  border-radius: 34px;
}
.c-input--switch__slider.c-input--switch__round:before {
  border-radius: 50%;
}

/* DROP-DOWN SELECTION */
.c-input--select:invalid {
  color: #a9a9a9;
}
.c-input--select--dark:invalid {
  color: rgba(33, 33, 33, 0.6);
}
.c-input--number {
  max-width: 5vw;
}

/* hide default file input, but keep some dimension for accessibility */
.c-input--file {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
  /* pointer cursor on hover */
  /* keyboard nav -- accessibility */
}
.c-input--file + label {
    cursor: pointer;
}
.c-input--file:focus + label {
    outline: 1px dotted #000;
    outline: -webkit-focus-ring-color auto 5px;
}
.c-label--filedrop {
  min-height: 7rem;
  max-height: 7rem;
  border: 2px dashed #518668;
  /* subtract a bit for button padding */
  line-height: 6rem;
  margin: 0 2rem;
}
.c-label--filedrop:hover, .c-label--filedrop:focus {
    border: 2px dashed rgba(80, 134, 104, 0.5);
    background: rgba(80, 134, 104, 0.5);
}
.c-label {
  font: 1rem "Nunito Regular", Helvetica, sans-serif;
  line-height: 1.2;
  margin: .25rem 1rem .25rem .25rem;
}
.c-label--dark {
  font: 1rem "Nunito Regular", Helvetica, sans-serif;
  line-height: 1.2;
  color: #eceff1;
  margin: .25rem 1rem .25rem .25rem;
}
.c-fieldset {
  margin: .25rem 1rem .25rem .25rem;
}
.c-form-block--inline {
  display: flex;
  align-items: baseline;
}

/*
 * FULL FORM FORMATTING
 */
.c-form-container {
  margin: 0 auto;
  max-width: 50vw;
}
.c-form-container--slim {
  margin: 0 auto;
  max-width: 35vw;
}
.c-input--short {
  max-width: 10rem;
}
.c-form-field {
  padding: 1rem;
}

/*------------------------------------*\
   #UTILITIES
\*------------------------------------*/
/*------------------------------------*\
    #VISIBILITY CLASSES
\*------------------------------------*/
/**
 * Is Hidden
 * 1) Completely remove from the flow and screen readers.
 */
.u-is-hidden {
  display: none !important;
  visibility: hidden !important;
}

/**
 * Is Visibly Hidden
 * 1) Completely remove from the flow but leave available to screen readers.
 */
.u-is-vishidden {
  position: absolute !important;
  overflow: hidden;
  width: 1px;
  height: 1px;
  padding: 0;
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
}

/*------------------------------------*\
    #DISPLAY CLASSES
\*------------------------------------*/
/**
 * Display block
 * 1) Add display block by overriding
 */
.u-display-block {
  display: block !important;
}

/**
 * Margin Bottom Double
 * 1) Adds double the base unit of margin
 *    to the bottom of an element
 */
.u-margin-bottom-double {
  margin-bottom: 2rem !important;
}
.u-center {
  display: block !important;
  margin: 0 auto !important;
}
.u-text-center {
  text-align: center;
}
.u-padding-side {
  padding-left: 4rem;
  padding-right: 4rem;
}

/* right align an element */
.u-pull-right {
  float: right;
}
.unavailable {
  color: rgba(176, 190, 197, 0.5) !important;
  cursor: default;
}
.unavailable-light {
  color: #ccc !important;
  cursor: default;
  fill: #ccc;
}
.u-dark {
  /* somewhat misleadingly named -- for dark backgrounds but text is light */
  background-color: #22543A !important;
  color: #fff !important;
}
.u-light {
  /* as above, for light backgrounds */
  color: #212121 !important;
}
.serif {
  font-family: "Bitter Regular", "Book Antiqua", Palatino, serif;
}
.sans-serif {
  font-family: "Nunito Regular", Helvetica, sans-serif;
}

/*------------------------------------*\
    #Theme Colors
\*------------------------------------*/
.primary-color{
  color: #518668;
}
/* for coloring on plotly links in CatStatsAllAgentBubblePlot, CatstatsBarChart, and CatStatsBubblePlot */
.js-plotly-plot .plotly svg a{
  fill: #518668 !important;
}
.primary-thin-border{
  border: 0.5px solid rgba(81, 134, 104, 0.5) !important;
}
.primary-solid-border{
  border: 1px solid #518668 !important;
}
.primary-thick-border-hover:hover{
  border: 2px solid #518668 !important;
}
.primary-shaded-background, .primary-shaded-background-hover:hover{
  background: rgba(81, 134, 104, 0.3) !important;
}
.primary-heavy-shaded-background, .primary-heavy-shaded-background-hover:hover{
  background: rgba(81, 134, 104, 0.5) !important;
}
.lighter-shaded-background{
  background: rgba(119, 192, 94, 0.2) !important;
}
.primary-border-bottom{
  border-bottom: 1px solid #518668 !important;
}
.primary-shaded-border-top{
  border-top: 0.5px solid rgba(81, 134, 104, 0.5) !important;
}
.primary-shaded-border-bottom-last-child:last-child, .primary-shaded-border-bottom{
  border-bottom: 0.5px solid rgba(81, 134, 104, 0.5) !important;
}
.primary-border-top{
  border-top: 1px solid #518668 !important;
}
.lighter-border-top{
  border-top: 1.2px solid #77C05E !important;
}
.lighter-border-top-thin{
  border-top: 0.5px solid #77C05E !important;
}
.primary-border-button, .primary-border-active-button:active{
    border: 1px solid #518668 !important;
    background: white !important;
    color: #518668 !important;
}
.primary-color-button{
  background: #518668;
  color: white;
}
.primary-background-hover:hover, .primary-background-focus:focus, .primary-background{
  background: #518668 !important;
}
.primary-background-text-color-hover:hover, .primary-background-text-color-focus:focus, .primary-background-text-color{
  color: #FFFFFF !important;
}
.darker-background-hover:hover{
  background: #23593D !important;
}
