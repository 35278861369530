
.l-page-layout__secondary[data-v-1c59f120]{
  overflow:visible;
}
.c-primary-nav-vertical[data-v-1c59f120]{
  position:relative;
}
.c-primary-nav-vertical__item[data-v-1c59f120]{
  margin:0rem;
}
.logo-text[data-v-1c59f120]{
  font-size:20px;
  padding-left:10px;
}
.logo-container[data-v-1c59f120]{
  display:flex;
  flex-direction: row;
  align-items: center;
  letter-spacing: 0.02em;
  color: #ECEFF1;
  font-weight: bold;
  margin: 30px 0px 60px 20px;
}
.c-logo-vertical[data-v-1c59f120]{
  margin:0;
  width:28px;
  height:28px;
  min-width:0rem;
}
.c-logo_img[data-v-1c59f120]{
  width:28px;
  height:28px;
}
.dropdown-nav[data-v-1c59f120]{
  padding: 0px 39px 33px 23px;
}
.dropdown-item[data-v-1c59f120]{
  padding-bottom:14px;
}
.c-icon[data-v-1c59f120]{
  fill: #B0BEC5;;
  margin-right:15px;
}
.c-input--select[data-v-1c59f120]{
  background-color: rgba(226, 241, 248, 0.4);
  color: #ECEFF1;
}
.c-input--select option[data-v-1c59f120]{
  background-color: rgba(109, 116, 120);
  color: #ECEFF1;
}
.footer[data-v-1c59f120]{
  position: absolute;
  bottom: 20px;
  line-height: 13pt;
}
.footer-label[data-v-1c59f120]{
  font-size: .8em;
}
.c-header-vertical__inner[data-v-1c59f120] {
    flex-direction: column;
    padding: 1rem;
    align-items: flex-start;
    min-height: 100vh;
}


