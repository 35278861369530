
.modal-mask[data-v-7a1d9504] {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 17rem;
    width: 86vw;
    height: 100%;
    background-color: rgba(176, 190, 197, 0.5);
}
.modal-container[data-v-7a1d9504] {
    max-width: 600px;
    max-height: 95vh;
    margin: 1.75rem auto;
    padding: 1rem 2rem;
    background-color: #fff;
    box-shadow: -4px 4px 4px rgba(49, 48, 48, 0.1);
    border-radius: 0.6rem;
    display:flex;
    flex-direction: column;
    font-size:12px;
}
.modal-header[data-v-7a1d9504] {
  margin-top: 0;
  font-size: 20px;
  font-weight: bold;
}
.modal-subheader[data-v-7a1d9504]{
    font-size: 12px;
}
.button-container[data-v-7a1d9504]{
    display: flex;
    justify-content: flex-end;
    padding-top: 20px;
}
.button-base[data-v-7a1d9504]{
    width: 18%;
    border-radius: 3px; 
    text-align: center;
    padding: 5px 0px;
    font-size: 14px;
}
.button-base[data-v-7a1d9504]:hover{
    cursor: pointer;
}
.go-back[data-v-7a1d9504]{
    box-sizing: border-box;
    margin-right: 5px;
}
.save[data-v-7a1d9504]{
    margin-left: 5px;
}
.modal-wrapper[data-v-7a1d9504]{
    margin-top: 200px;
}
.disc-list[data-v-7a1d9504]{
    list-style: disc
}
