
.right-margin[data-v-443c8396] {
    margin-right: 1rem;
}
.fixed[data-v-443c8396] {
    position: fixed;
}
.submenu[data-v-443c8396] {
    width: 10rem;
    right: .5rem;
    color: #2F2F2F;
    top: 4rem;
    background-color: white;
}
