
.side-nav[data-v-5fbf8302]{
    width:17rem;
}
.router-view[data-v-5fbf8302]{
    margin-left:17rem;
    background:#FFFFFF;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-content: space-between;
}
.login-view[data-v-5fbf8302]{
    margin-left:0rem;
}
html[data-v-5fbf8302]{
  min-height: 100%;
}
