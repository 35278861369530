
.plot-container {
  min-height: 150px;
}
.loader-container {
  margin-top: 140px;
  vertical-align: middle;
  min-height: 350px;
}
.loader-container-page {
    height: 100vh;
    background: #FFFFFF;
}
.loader-container-auto {
    margin: auto;
}
.loader {
    border: 16px solid #CAA8F4; 
    border-top: 16px solid #69C9FF; 
    border-radius: 50%;
    width: 175px;
    height: 175px;
    animation: spin 3s linear infinite;
    margin: auto;
    border-top-color: #4B7F63;
    border-bottom-color: #4B7F63;
    border-left-color: #E5E5E5;
    border-right-color: #E5E5E5;
}
@keyframes spin {
0% { transform: rotate(0deg);
}
100% { transform: rotate(360deg);
}
}
.loader-page {
    position: absolute;
    top: 40%;
    left: 50%;
}
.loader-small {
    border: 16px solid #CAA8F4; 
    border-top: 16px solid #69C9FF; 
    border-radius: 50%;
    width: 105px;
    height: 105px;
    animation: spin 3s linear infinite;
    margin: auto;
    border-top-color: #4B7F63;
    border-bottom-color: #4B7F63;
    border-left-color: #E5E5E5;
    border-right-color: #E5E5E5;
}
@keyframes spin {
0% { transform: rotate(0deg);
}
100% { transform: rotate(360deg);
}
}
.loader-tiny {
    border: 5px solid #CAA8F4; 
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 3s linear infinite;
    margin: auto;
    border-top-color: #4B7F63;
    border-bottom-color: #4B7F63;
    border-left-color: #E5E5E5;
    border-right-color: #E5E5E5;
}
@keyframes spin {
0% { transform: rotate(0deg);
}
100% { transform: rotate(360deg);
}
}
