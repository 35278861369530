

























































































































/* Original */
/* font-size: 16px; */
.nav-item__button[data-v-268fb83a] {
  background-color: #212121;
  border: none;
}
.button[data-v-268fb83a]:focus{
  outline:0px;
}
.c-primary-nav-vertical__text.active-page[data-v-268fb83a] {
  font-family: "Nunito Bold", Helvetica, sans-serif;
  color: #FFFFFF;
}
.c-icon[data-v-268fb83a]{
  fill: #B0BEC5;;
  margin-right:15px;
  width: 23px;
  height: 23px;
}
.c-primary-nav-vertical__item.is-active .c-icon[data-v-268fb83a]{
  fill: #ECEFF1;
}
.c-primary-nav-vertical__link[data-v-268fb83a]{
    padding: 1rem 16px 1rem 23px;
    width:100%;
}
.c-primary-nav-vertical__link[data-v-268fb83a]:focus, .c-primary-nav-vertical__link[data-v-268fb83a]:hover {
  font-family: "Nunito Bold", Helvetica, sans-serif;
}
.c-primary-nav-vertical__link:focus .c-icon[data-v-268fb83a], .c-primary-nav-vertical__link:hover .c-icon[data-v-268fb83a]{
  fill: #ECEFF1;
}
.inline-block[data-v-268fb83a] {
  display: inline-block;
  width: 100%;
}
.sub-menu[data-v-268fb83a] {
  display: none;
  position: absolute;
  /* right: -18vh; */
  right: -68%;
  width: 185px;
}
.nav-item-wrapper:hover .sub-menu[data-v-268fb83a] {
  display: inline-block;
}

