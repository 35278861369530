
.base-header-row[data-v-596181b0]{
    height: 35px;
    font-weight: bold;
    font-size: 14px;
}
.header-row[data-v-596181b0]{
    background: rgba(39, 149, 251, 0.15);
}
.disabled-header-row[data-v-596181b0]{
    background: rgba(33, 33, 33, 0.3);
}
.list-row[data-v-596181b0]{
    height: 30px;
    padding: 10px 10px;
    border-bottom: 1px solid rgba(39, 149, 251, 0.25);
}
.flex-row[data-v-596181b0]{
    display: flex;
}
.base-action-button[data-v-596181b0]{
    box-sizing: border-box;
    border-radius: 3px;
    max-width: 80px;
    overflow: hidden;
    white-space: nowrap;
    padding: 0 5px;
    height: 20px;
    text-align: center;
    margin: 0 1px;
}
.action-button[data-v-596181b0]:hover{
    cursor: pointer;
}
.disabled-action-button[data-v-596181b0]{
    background: rgba(33, 33, 33, 0.3);
    color: #212121;
}
.entity-list[data-v-596181b0]{
    width: 250px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    border-radius: 3px;
    -webkit-column-gap:0;
    column-gap:0;
}
.base-list-body[data-v-596181b0]{
    height: 195px;
    overflow-y: scroll;
    font-weight: normal;
    font-size: 12px;
}
.list-body[data-v-596181b0]{
    background: white;
}
.disabled-list-body[data-v-596181b0]{
    background: rgb(200, 200, 200);
}
.action-cell-body[data-v-596181b0]{
    display: flex;
    align-items: center;
    justify-content: center;
}
.action-cell[data-v-596181b0]{
    width:83px;
    justify-content: center;
}
.entity-cell[data-v-596181b0]{
    width: 83px;
}
.entity-cell-body[data-v-596181b0]{
    display: flex;
    align-items: center;
    font-size: 10px;
}
.base-cell[data-v-596181b0]{
    display: flex;
    /* justify-content: center; */
    align-items: center;
}
.title-info-container[data-v-596181b0]{
    display:flex;
}
.list-title[data-v-596181b0]{
    font-size: 16px;
    margin-right: 5px;
}
.list-count[data-v-596181b0]{
    margin-top: 20px;
    font-size: 12px;
    margin-bottom: 5px;
    height: 20px;
}
.pad-right-title[data-v-596181b0]{
    padding-right: 4px;
}
.centered-cell[data-v-596181b0]{
    justify-content: center;
}
.first-column-padding-left[data-v-596181b0]{
    padding-left: 10px;
}
.abbreviated-cell[data-v-596181b0]{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.group-by-filter-select[data-v-596181b0]{
    width:140px;
    height:25px;
    font-size: 12px;
    padding-left:11px;
    margin-right:10px;
    padding-top:0px;
    padding-bottom:0px;
}
