
.cell-container[data-v-0c3d5f40]{
    display: flex;
    flex-direction: column;
    width: 20%;
}
.open-button[data-v-0c3d5f40]{
    height: 1rem;
    width: 1rem;
    cursor: pointer;
}
img[data-v-0c3d5f40]{
    max-width: unset;
}
