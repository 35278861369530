
.header {
    align-content: center;
    background-color: #1D5035;
    display: flex;
    flex-direction: row;
    height: 3.5em;
    justify-content: space-around;
    padding: 5px;
    position: fixed;
    width: 100vw;
    z-index: 3;
}
.header__title{
    border-right: 1px solid white;
    flex: 1;
    padding-left: .5em;
}
.header__page-title{
    flex: 2;
    padding-left: .5em;
}
.header__company-dropdown{
    flex: 6;
    padding-left: .5em;
}
.header__navigation-page-link{
    cursor: pointer;
    flex: 1;
    padding-left: .5em;
}
div.header + div {
    padding-top: 120px;
}
