
.multiSelect[data-v-4d444e90] {
    width: 100%;
    box-sizing: border-box;
    border: 1px solid rgba(1, 1, 1, 0);
}
.multiselect-container[data-v-4d444e90] {
    display: flex;
}
.clear-button[data-v-4d444e90] {
    border-radius: 5px;
    background-color: rgb(110,116,119); 
    color: white;
    outline:none;
}
.multiselect[data-v-4d444e90] .multiselect__input {
    font-size: 10px;
}
.multiSelect[data-v-4d444e90] .multiselect, .multiSelect[data-v-4d444e90] .multiselect__tags {
    min-height:  unset;
    height: 100%;
}
.multiSelect[data-v-4d444e90] .multiselect__tags {
    transition: all 0.1s ease-in-out;
    font: 1rem "Nunito Regular", Helvetica, sans-serif;
    line-height: 1.5rem;
    font-size: 12px;
    height:2.5rem;
    overflow: scroll;
    /* the two below hide the scroll bars for their respective browswers */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}
.multiSelect[data-v-4d444e90] .multiselect__tags::-webkit-scrollbar { 
    /* the below hides the scroll bars for their respective browswers */
    display: none;  /* Safari and Chrome */
}
.multiSelect[data-v-4d444e90] .multiselect__tag-icon:after {
    content: "\D7";
    font-size: 14px;
}
.multiSelect[data-v-4d444e90] .multiselect__tag-icon {
    cursor: pointer;
    margin-left: 0px;
    font-weight: 700;
    font-style: normal;
    width: 22px;
    text-align: center;
    transition: all .2s ease;
    border-radius: 5px;
}
.multiSelect[data-v-4d444e90] .multiselect__tags-wrap {
    padding-left: 3px;
}
.multiSelect[data-v-4d444e90] .multiselect__tag {
    display: inline-flex;
    padding-left: 5px;
    border: 1px solid;
    border-radius: 5px;
    margin: 1px;
}
.multiSelect[data-v-4d444e90] .multiselect--active .multiselect__select{
    border-left: none;
}
.mymultiselect[data-v-4d444e90] .multiselect__option{
    padding: unset;
    min-height: unset;
    line-height: unset;
}
.mymultiselect[data-v-4d444e90] .multiselect__option--group {
    min-height: 40px;
}
.mymultiselect[data-v-4d444e90] .multiselect, 
.mymultiselect[data-v-4d444e90] .multiselect__input, 
.mymultiselect[data-v-4d444e90] .multiselect__single {
    font-size: 10px;
}
.mymultiselect[data-v-4d444e90] .multiselect__option--selected {
    background: #fff;
}
.mymultiselect[data-v-4d444e90] .multiselect__option--selected {
    font-weight: unset;
}
.mymultiselect[data-v-4d444e90] ul {
    padding-inline-start: 0px !important;
}
.mymultiselect[data-v-4d444e90] .c-label--checkbox {
    padding: .25rem 0 .25rem 3rem;
}
.mymultiselect[data-v-4d444e90] .c-input--checkbox__indicator {
    top: .285rem;
    left: 1rem;
}
.mymultiselect[data-v-4d444e90] .multiselect__content-wrapper {
    height: 180px;
    width: 225px;
    position: absolute;
    background-color: rgb(110,116,119); 
    border-radius: 0px 0px 3px 3px;
    border-right: 1px solid;
    border-bottom: 1px solid;
    border-left: 1px solid;
    border-color: rgba(80, 134, 104, 0.5);
    z-index:10000;
    margin-top: 32px;
    overflow: scroll;
    /* the two below hide the scroll bars for their respective browswers */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}
.mymultiselect_nosearch[data-v-4d444e90] .multiselect__content-wrapper {
  margin-top: 1px;
  height: 96px;
  border-top: 1px solid;
  border-color: rgba(80, 134, 104, 0.5);
}
.mymultiselect[data-v-4d444e90] .multiselect__content::-webkit-scrollbar { 
    /* the below hides the scroll bars for their respective browswers */
    display: none;  /* Safari and Chrome */
}
.mymultiselect[data-v-4d444e90]{
    background-color: rgb(110,116,119); 
    border-radius: 3px;
    border: 1px solid rgba(80, 134, 104, 0.5);
}
.multiSelect[data-v-4d444e90] .multiselect__input {
    position: absolute;
    top: 148px;
    font-size: 14px;
    width: 225px !important;
    height:32px;
    background-color: rgb(110,116,119);
    border-radius: 3px 3px 0px 0px;
    border-top: 1px solid;
    border-right: 1px solid;
    border-left: 1px solid;
    border-color: rgba(80, 134, 104, 0.5);
    z-index:10000;
    color: white;
    display:none;
}
.multiSelect[data-v-4d444e90] .multiselect__input:focus{
    outline:none;
}
.multiSelect[data-v-4d444e90] .multiselect__input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white;
  opacity: 1; /* Firefox */
}
.multiSelect[data-v-4d444e90] .multiselect__placeholder{
    display:none;
}
.mymultiselect_nosearch[data-v-4d444e90] .multiselect__placeholder{
    display:flex;
    padding: 5px;
}


