
.container {
  background-color: white;
  grid-column: 2 / 4;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto auto;
  grid-gap: 30px;
}
.content {
  grid-column: 1 / 1;
  grid-row: 2;
  padding-left: 35px;
  padding-right: 35px;
}
.item {
    grid-column: 1 / 1;
}
.header {
  grid-column: 1 / 1;
  grid-row: 1;
  padding: 50px;
}
.grid {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: auto auto auto auto;
    grid-template-rows: auto auto;
}
