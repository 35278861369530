
.info-icon[data-v-0b5d2df8]{
    margin-left: 2px;
    text-align: center;
}
.info-image[data-v-0b5d2df8]{
    vertical-align: middle;
}
.info-icon[data-v-0b5d2df8]:hover{
    cursor: pointer;
}
.info-text[data-v-0b5d2df8]{
    position: absolute;
    z-index: 999999;
    margin-left: -6px;
    margin-top: -2px;
}
.main-info-box[data-v-0b5d2df8]{
    background: #FFFFFF;
    border: 0.5px solid #B0BEC5;
    box-sizing: border-box;
    box-shadow: -2px 2px 4px rgba(0, 0, 0, 0.2);
    padding:20px;
    font-size:12px;
    width: 20rem;
    line-height: 175%;
    text-align: left;
}
.arrow-up[data-v-0b5d2df8] {
  width: 0; 
  height: 0; 
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  
  border-bottom: 11px solid #B0BEC5;
}
.arrow-holder[data-v-0b5d2df8]{
    padding-left:9px;
}
