
.c-icon[data-v-7227ade3]{
fill: #B0BEC5;;
margin-right:15px;
width: 18px;
height: 18px;
margin-right: auto;
}
.middle-align[data-v-7227ade3] {
    vertical-align: middle;
}
.icon-pointer[data-v-7227ade3]:hover {
    cursor: pointer;
}
.fa-icon[data-v-7227ade3] {
    display: inline-block;
}

/* style to override the sideNav's light colored text */
.black-text[data-v-7227ade3]{
    color: #444;
}

/* info tooltip styles for the list & plus functional area icon -- pulled from the InfoTooltip common component */
.info-text[data-v-7227ade3]{
    position: absolute;
    z-index: 999999;
    margin-left: -6px;
    margin-top: -2px;
}
.main-info-box[data-v-7227ade3]{
    background: #FFFFFF;
    border: 0.5px solid #B0BEC5;
    box-sizing: border-box;
    box-shadow: -2px 2px 4px rgba(0, 0, 0, 0.2);
    padding:20px;
    font-size:12px;
    width: fit-content;
    line-height: 175%;
    text-align: left;
}
.arrow-up[data-v-7227ade3] {
width: 0; 
height: 0; 
border-left: 5px solid transparent;
border-right: 5px solid transparent;

border-bottom: 11px solid #B0BEC5;
}
.arrow-holder[data-v-7227ade3]{
    padding-left:9px;
}

