
.no-data-container[data-v-70566b8d]{
    font-size: 14px;
    color: #808E95;
    display:flex;
    justify-content: center;
    padding: 20px;
    padding-top: 60px;
}
.search-filter-padded[data-v-70566b8d]{
    padding-left: 20px;
    padding-bottom:10px;
    width: 15rem;
}
.search-filter[data-v-70566b8d]{
    padding-bottom:10px;
    width: 15rem;
}
.paginated-table-container[data-v-70566b8d]{
    display:flex;
    flex-direction: row;
    align-items: center;
}
.paginated-table-title[data-v-70566b8d] {
    font-weight: bold;
    font-size: 20px;
    padding-bottom:10px;
    padding-right: 5px;
}
.paginated-table-info-tip[data-v-70566b8d] {
    padding-bottom: 10px;
}
