
.table-container[data-v-2d4e71f4]{
    display:flex;
    flex-direction: column;
}
.table-header-row[data-v-2d4e71f4]{
    display: flex;
    flex-direction: row;
    background: rgba(39, 149, 251, 0.15);
    padding: 0.5rem;
    font-weight: bold;
    font-size: 12px;
}
.header-cell[data-v-2d4e71f4]{
    display: flex;
}
.header-cell span[data-v-2d4e71f4]{
    padding-left: 4px;
    padding-right: 4px;
    align-self: center;
    text-align: center;
}
.total-row[data-v-2d4e71f4]{
    background: rgba(39, 149, 251, 0.08)
}
.table-body-cell[data-v-2d4e71f4]{
    word-wrap: break-word;
    padding-right: 10px;
    display: flex;
    align-items: center;
}
.table-body-row[data-v-2d4e71f4]{
    display:flex;
    flex-direction: row;
    font-size: 10px;
    justify-content: space-between;
    padding: 0.5rem;
    border-bottom: 0.5px solid rgba(39, 149, 251, 0.5);
}
.table-body[data-v-2d4e71f4]{
    height:100%;
    overflow-y:auto;
}
.hover-row[data-v-2d4e71f4]:hover{
    cursor: pointer;
    background: rgba(39, 149, 251, 0.1);
}
.abbreviated-cell[data-v-2d4e71f4]{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.centered-cell[data-v-2d4e71f4]{
    justify-content: center;
}
.open-button[data-v-2d4e71f4]:hover{
    cursor: pointer;
}
.open-button[data-v-2d4e71f4]{
    height: 15px;
}
.clickable-cell[data-v-2d4e71f4]:hover{
    cursor: pointer;
}
.clickable-cell[data-v-2d4e71f4]{
    text-decoration-line: underline;
}
