
.modal-mask[data-v-60e1919d] {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 17rem;
    width: 86vw;
    height: 100%;
    background-color: rgba(176, 190, 197, 0.5);
}
.modal-container[data-v-60e1919d] {
    width: 58vw;
    max-height: 95vh;
    margin: 1.75rem 8rem auto;
    padding: 1rem 2rem;
    background-color: #fff;
    box-shadow: -4px 4px 4px rgba(49, 48, 48, 0.1);
    border-radius: 0.6rem;
    display:flex;
    flex-direction: column;
    font-size:16px;
}
.modal-header[data-v-60e1919d] {
  margin-top: 0;
  padding-top: 0.5rem ;
  border-bottom: 1px solid #444;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
}
.modal-body[data-v-60e1919d] {
    padding:  3rem;
    display: block;
    align-items: right
}
.modal-footer[data-v-60e1919d]{
    width: 100%;
    margin-bottom: 25px;
}
.delete-icon[data-v-60e1919d] {
    width: 2rem;
    cursor: pointer;
}
.base-info[data-v-60e1919d]{
    display: table;
    position: relative;
    width: 100%;
    align-items: center;
    padding-bottom: 5px;
}
.base-info-item[data-v-60e1919d]{
    display: table-cell;
}
.config-section[data-v-60e1919d]{
    margin-top: 2rem;
}
.section-header[data-v-60e1919d] {
    margin-bottom: 1rem;
    text-decoration: underline;
}
thead[data-v-60e1919d], tbody[data-v-60e1919d]{
 border-bottom: 1px solid black;
}
td[data-v-60e1919d]{
    padding:0.25rem 0 0.25rem 0;
}
tr[data-v-60e1919d]{
    padding-bottom: 0.25rem;
}
.c-input--text[data-v-60e1919d], .c-input--select[data-v-60e1919d]{
    width: 5.5rem;
    height: 2rem;
    font-size: 16px;
}
.target-input[data-v-60e1919d]{
    display: inline-flex;
    position: relative;
}
.target-unit[data-v-60e1919d], .target-unit-static[data-v-60e1919d] {
    margin: 0 0.5rem;
}
.target-unit-static[data-v-60e1919d]{
    padding-top:0.5rem;
}
.share[data-v-60e1919d]{
    padding-top:0.5rem;
    width: 30%;
}
.column-weight[data-v-60e1919d]{
    width: 20%;
}
.column-status[data-v-60e1919d]{
    width: 20%;
}
.column-target[data-v-60e1919d]{
    width: 25%;
}
.disabled[data-v-60e1919d]{
    color: gray;
}
.error-message-tooltip[data-v-60e1919d]{
    position:absolute;
    bottom:100%;
    white-space: nowrap;
    font-size: 12px;
    content: '';
    background-color: #FED0D0;
}
.error-message-tooltip[data-v-60e1919d]::after {
  content: " ";
  position: absolute;
  top: 100%; /* At the bottom of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #FED0D0 transparent transparent transparent;
}
.submit-button-container[data-v-60e1919d]{
    display: flex;
    justify-content: center;
}
.footer-btn[data-v-60e1919d]{
    width: 40%;
    margin: 1rem;
}
.c-btn[data-v-60e1919d]:disabled{
    background-color: gray;
    opacity: 40%;
    cursor: default;
}
.flex-column[data-v-60e1919d]{
    display: flex;
    flex-direction: column;
}
.flex-row[data-v-60e1919d]{
    display: flex;
    justify-content: center;
}
.success-failure-label[data-v-60e1919d]{
    font-size: 20px;
}
.ticket-icon[data-v-60e1919d]{        
    height: 35px;
    width: 35px;
    margin-right: 7px;
}
.c-clickable[data-v-60e1919d]{
    cursor: pointer;
}
.active-switch[data-v-60e1919d]{
    vertical-align: middle;
    margin-right: 10px;
} 

/* style for the exclusion lists */
.list-container[data-v-60e1919d]{
    display:flex;
    justify-content: space-evenly;
}
.margin-right[data-v-60e1919d]{
    margin-right:10px;
}

/* style to simulate disabling dropdown & exclusion lists after saving functionalArea */
.div-disabled[data-v-60e1919d]{
    background-color: gray;
    opacity: 40%;
    cursor: default;
}

/* style to override the sideNav's light colored text */
.black-text[data-v-60e1919d]{
    color: #444;
}

/* style to override specific html sections defined in the EntityList component */
div[data-v-60e1919d] .list-count{
    margin-top: 10px !important
}

